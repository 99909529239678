import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "components/ui/form";
import { Button } from "components/ui/button";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import { Input } from "components/ui/input";
import { RequestApi, RouteApi } from "utils";
import { useState } from "react";
import { Eye, EyeOff, KeyRound, Loader, Phone } from "lucide-react";

export default function AlertLoginPage() {
  const [loading, setLoading] = useState<boolean>();
  const [showPassword, setShowPassword] = useState("password");
  const [errorState, setErrorState] = useState<string>("");

  const toggleShowPassword = () => {
    if (showPassword === "text") {
      setShowPassword("password");
    } else {
      setShowPassword("text");
    }
  };
  const FormSchema = z.object({
    phone: z.string().min(1, {
      message: "Phone number is required*",
    }),
    password: z.string().min(1, {
      message: "Password is required*",
    }),
  });
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      phone: "",
      password: "",
    },
  });

  const onSubmit = async (data: z.infer<typeof FormSchema>) => {
    setLoading(true);
    try {
      const result = await RequestApi(RouteApi.login, {
        method: "POST",
        data: {
          username: data.phone,
          password: data.password,
        },
      });

      if (result.status === "success") {
        const { token, user } = result;
        localStorage.setItem("jwt", token);
        localStorage.setItem("user", JSON.stringify(user));

        console.log("Login successful:", { token, user });

        window.location.href = "/";
      } else {
        setErrorState(result.msg);
      }
      setLoading(false);
    } catch (error: any) {
      console.error("Unexpected error during login:", error);
      setLoading(false);
    }
  };

  return (
    <>
      <p className="pb-10 font-medium text-center">
        Build your social communication with FlipFlop social media
      </p>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="text-start space-y-4"
        >
          <FormField
            control={form.control}
            name="phone"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <div className="relative">
                    <div
                      className={`absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none`}
                    >
                      <Phone />
                    </div>
                    <Input
                      type="text"
                      className="rounded-xl pl-12 text-black"
                      placeholder="Phone No."
                      {...field}
                    />
                  </div>
                </FormControl>
                <div className="text-red-600">
                  <FormMessage />
                </div>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem className="">
                <FormControl>
                  <div className="relative w-full">
                    <div
                      className={`absolute left-0 top-2 start-0 ps-3.5 pointer-events-none`}
                    >
                      <KeyRound />
                    </div>
                    <Input
                      type={`${showPassword}`}
                      className="rounded-xl pl-12 text-black w-full"
                      placeholder="Password"
                      {...field}
                    />
                    {showPassword === "password" ? (
                      <div className="flex">
                        <div onClick={() => toggleShowPassword()}>
                          <Eye
                            className={`absolute right-3 top-2 cursor-pointer`}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="flex">
                        <div onClick={() => toggleShowPassword()}>
                          <EyeOff
                            className={`absolute right-3 top-2 cursor-pointer `}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </FormControl>
                <div className="text-red-600">
                  <FormMessage />
                </div>
              </FormItem>
            )}
          />

          <div className="text-red-600">{errorState}</div>

          <div className="w-full flex flex-col">
            <Button className="bg-[#F3BE22] hover:bg-[#fad56d] rounded-xl text-white">
              {loading ? <Loader className="animate-spin" /> : "Login"}
            </Button>
          </div>
        </form>
      </Form>
    </>
  );
}
