import { zodResolver } from "@hookform/resolvers/zod";
import { otp } from "assets";
import { Snackbar } from "components";
import ImageUrl from "components/app/image-url/ImageUrl";
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogHeader,
  AlertDialogTitle,
} from "components/ui/alert-dialog";
import { Button } from "components/ui/button";
import { Form, FormControl, FormField, FormItem } from "components/ui/form";
import { InputOTP, InputOTPGroup, InputOTPSlot } from "components/ui/input-otp";
import { Loader, X } from "lucide-react";
import { AppOTPFunction } from "models/appOTPFunction";
import { StatusMsg } from "models/returnMsg";
import React, { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import OneSignal from "react-onesignal";
import { useNavigate } from "react-router-dom";
import { AppLog } from "services/appLog";
import { RequestApi, RouteApi, RouteUrl } from "utils";
import {
  BrowserDeviceInfo,
  convertDeviceInfoToMap,
  getBrowserDeviceInfo,
} from "utils/deviceInfo";
import { z } from "zod";
interface AlertOtp {
  showAlert: boolean;
  setShowAlert: (value: boolean) => void;
  dataForm: any;
  onReset?: (value: any) => void;
}

export default function OtpPage({
  showAlert,
  setShowAlert,
  dataForm,
  onReset,
}: AlertOtp) {
  const [loading, setLoading] = useState<boolean>();
  const [alert, setAleart] = useState<boolean>(true);
  const [otpValues, setOtpValues] = React.useState<string[]>(Array(4).fill(""));
  const [deviceInfo, setDeviceInfo] = useState<BrowserDeviceInfo | null>(null);
  const [errorState, setErrorState] = useState<string>("");
  const navigate = useNavigate();
  const FormSchemaOtp = z.object({
    pin: z.string().min(4, {
      message: "Your one-time password must be 6 characters.",
    }),
  });
  const formOtp = useForm<z.infer<typeof FormSchemaOtp>>({
    resolver: zodResolver(FormSchemaOtp),
    defaultValues: {
      pin: "",
    },
  });
  const onOtpSingUp = async (data: z.infer<typeof FormSchemaOtp>) => {
    try {
      setErrorState("");
      setLoading(true);
      if (dataForm!?.otp_function === AppOTPFunction.resetPassword) {
        const result = await RequestApi(RouteApi.activationResetPassword, {
          data: {
            token: dataForm?.token,
            activation_code: data.pin,
            app_key: "LyCqMkT2uxL1JAy9UofOmhCnBeJCEblWYOrfHWCL",
          },
        });
        if (result.status === "success") {
          const dataOtp = { token: dataForm?.token, user: result["user"] };
          if (onReset) {
            onReset(dataOtp);
          } else {
            navigate(RouteUrl.resetPassword, { state: dataOtp });
          }
        } else {
          setErrorState(result["msg"]);
          Snackbar({
            message: result["msg"],
            status: StatusMsg.getType(result["status"]),
          });
        }
      } else {
        const result = await RequestApi(RouteApi.activation, {
          data: {
            token: dataForm?.token,
            activation_code: data.pin,
            app_key: "LyCqMkT2uxL1JAy9UofOmhCnBeJCEblWYOrfHWCL",
            device_data:
              deviceInfo === null ? "" : convertDeviceInfoToMap(deviceInfo!),
            one_signal_id: OneSignal.User.PushSubscription.id ?? "",
            one_signal_token: OneSignal.User.PushSubscription.token ?? "",
            platform: "Web",
          },
        });
        console.log(JSON.stringify(convertDeviceInfoToMap(deviceInfo!)));
        if (result.status === "success") {
          AppLog({ message: `${JSON.stringify(result)}` });
          const { token, user } = result;
          localStorage.setItem("jwt", token); // Save the token
          localStorage.setItem("user", JSON.stringify(user)); // Save the user information
          // Log or handle user and token as needed
          console.log("Login successful:", { token, user });
          window.location.href = "/";
          //  go to interest page
          // store user and token
        } else {
          setErrorState(result["msg"]);
          Snackbar({
            message: result["msg"],
            status: StatusMsg.getType(result["status"]),
          });
        }
      }

      setLoading(false);
    } catch (error) {
      console.log(`error on otp ${error}`);
      setLoading(false);
    }
  };
  useEffect(() => {
    setDeviceInfo(getBrowserDeviceInfo());
  }, []);
  if (showAlert) {
    return (
      <>
        {" "}
        <AlertDialog open={alert}>
          <AlertDialogContent>
            <AlertDialogHeader className="relative items-center">
              <X
                onClick={() => setAleart(false)}
                className="absolute -top-2 -right-2 cursor-pointer"
              />
              <div className="pb-5 flex justify-center">
                <ImageUrl width={80} height={80} src={otp} alt="logo" />
              </div>
              <AlertDialogTitle className="text-center font-semibold">
                OTP Verification
              </AlertDialogTitle>{" "}
              <AlertDialogDescription className="text-black">
                The OTP code had sent{" "}
                {dataForm?.otp_provider_type.value.toUpperCase()} to{" "}
                {dataForm?.otp_receiver} Please check your{" "}
                {dataForm?.otp_provider_type.value.toUpperCase()} and input your
                OTP code.
              </AlertDialogDescription>
              <div className=" items-center mt-5  ">
                {" "}
                <Form {...formOtp}>
                  <form
                    className="text-center"
                    onSubmit={formOtp.handleSubmit(onOtpSingUp)}
                  >
                    <FormField
                      control={formOtp.control}
                      name="pin"
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <InputOTP maxLength={4} {...field}>
                              <InputOTPGroup>
                                {otpValues.map((_, index) => (
                                  <InputOTPSlot key={index} index={index} />
                                ))}
                              </InputOTPGroup>
                            </InputOTP>
                          </FormControl>
                        </FormItem>
                      )}
                    ></FormField>
                    <div className="text-red-600">{errorState}</div>
                    <div className="w-full flex flex-col pt-3">
                      <Button className="bg-[#F3BE22] hover:bg-[#fad56d] rounded-xl text-white h-9">
                        Submit
                      </Button>
                    </div>
                  </form>
                </Form>
              </div>
            </AlertDialogHeader>
          </AlertDialogContent>
        </AlertDialog>
      </>
    );
  } else {
    return (
      <>
        <div className="flex flex-col items-center text-center ">
          <p>
            {" "}
            The OTP code had sent{" "}
            {dataForm?.otp_provider_type.value.toUpperCase()} to{" "}
            {dataForm?.otp_receiver} Please check your{" "}
            {dataForm?.otp_provider_type.value.toUpperCase()} and input your OTP
            code.
          </p>{" "}
          <Form {...formOtp}>
            <form
              className="text-center mt-3 items-center"
              onSubmit={formOtp.handleSubmit(onOtpSingUp)}
            >
              <FormField
                control={formOtp.control}
                name="pin"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <InputOTP maxLength={4} {...field}>
                        <InputOTPGroup>
                          {otpValues.map((_, index) => (
                            <InputOTPSlot key={index} index={index} />
                          ))}
                        </InputOTPGroup>
                      </InputOTP>
                    </FormControl>
                  </FormItem>
                )}
              ></FormField>
              <div className="text-red-600">{errorState}</div>
              <div className=" flex flex-col pt-3">
                <Button className="bg-[#F3BE22] hover:bg-[#fad56d] rounded-xl text-white h-9">
                  {loading ? <Loader className="animate-spin" /> : "Submit"}
                </Button>
              </div>
            </form>
          </Form>
        </div>
      </>
    );
  }
}
