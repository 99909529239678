import { Input } from "components/ui/input";
import { Search, X } from "lucide-react";
import { useEffect, useState } from "react";

interface SearchBarType {
  value: string;
  onValueChange: (value: string) => void;
}

export default function SearchBar({ value, onValueChange }: SearchBarType) {
  const [searchText, setSearchText] = useState<string>(value);
  const [delayedValue, setDelayedValue] = useState<string>("");

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  useEffect(() => {
    // Set a timeout for delayed value change
    const handler = setTimeout(() => {
      setDelayedValue(searchText);
    }, 1000);

    // Cleanup the timeout if searchText changes before the timeout is complete
    return () => {
      clearTimeout(handler);
    };
  }, [searchText]);

  useEffect(() => {
    if (delayedValue !== "") {
      onValueChange(delayedValue);
    } else {
      onValueChange(delayedValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delayedValue]);

  return (
    <div className="relative">
      <Input
        type="text"
        placeholder="Search"
        className="w-full h-full px-4 py-2 bg-gray-200 text-gray-700 rounded-full shadow focus:outline-none focus:bg-white"
        value={searchText}
        onChange={handleInputChange}
      />
      <span className="absolute inset-y-0 right-0 flex items-center pr-3">
        {searchText === "" ? (
          <Search />
        ) : (
          <X
            className="z-30 cursor-pointer bg-black text-white p-1 rounded-full"
            onClick={() => {
              setSearchText("");
              onValueChange("");
            }}
          />
        )}
      </span>
    </div>
  );
}
