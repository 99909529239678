import axios, { AxiosError, AxiosResponse } from "axios";

interface RequestApiParams {
  data?: any;
  method?: string;
  headers?: Record<string, string>;
}

export const RequestApi = async (
  url: string,
  { data = "", method = "POST", headers = {} }: RequestApiParams = {}
): Promise<any> => {
  const token = "Bearer " + localStorage.getItem("jwt");
  const headersList = {
    Accept: "*/*",
    "Content-Type": "application/json",
    ...headers,
  };

  try {
    const response: AxiosResponse = await axios({
      url, // Use the baseURL from environment variables
      data,
      method,
      headers: headersList,
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      return {
        success: false,
        message: axiosError.message,
        status: axiosError.response?.status,
        data: axiosError.response?.data,
      };
    }
    return {
      success: false,
      message: "An unexpected error occurred",
    };
  }
};
