import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { RouteUrl } from "utils";
import LoginPage from "pages/auth/login/LoginPage";
import NotFoundPage from "./pages/page-not-found/NotFoundPage"; // Import the NotFoundPage component
import PrivateRoute from "./components/PrivateRoute";
import PublicRoute from "./components/PublicRoute";
import ShopMenuPage from "./pages/shop-menu/ShopMenuPage";
import WelcomePage from "./pages/welcome/IndexPage";
import TermOfPrivacyPage from "./pages/term-of-privacy/TermOfPrivacyPage";
import TermOfServices from "pages/term-of-services/TermOfService";
import PublicProfile from "pages/public-profile/PublicProfile";
import ViewShop from "pages/view-shop/ViewShop";
import ViewProduct from "pages/view-product/ViewProduct";
import ProfilePostContentsPageView from "pages/profile-post-contents-page-view/ProfilePostContentsPageView";
import Following from "pages/following/Following";
import NewsFeed from "pages/news-feed/NewsFeed";
import MarketPlaces from "pages/market-places/MarketPlaces";
import { useEffect } from "react";
import SigUpPage from "pages/auth/sign-up/SignUpPage";
import ForgotPasswordPhonePage from "pages/auth/forgot-password-phone/ForgotPasswordPhonePage";
import ResetPasswordPage from "pages/auth/reset_password/ResetPasswordPage";
import ForgotPasswordEmailPage from "pages/auth/forgot-password-email/ForgotPasswordEmailPage";
import { initOneSignal } from "utils/oneSignalService";
import ChooseInterestCategory from "pages/user-interest/ChooseInterestCategory";
import Profile from "pages/profile/Profile";
import { useAppSelector } from "store/store";
import Setting from "pages/setting/Setting";

function App() {
  useEffect(() => {
    initOneSignal();
  }, []);

  const { isAuthenticated } = useAppSelector((state) => state.auth);

  return (
    <Router>
      <Routes>
        <Route element={<PublicRoute />}>
          <Route
            path={RouteUrl.forgotPasswordPhone}
            element={<ForgotPasswordPhonePage />}
          />
          <Route
            path={RouteUrl.forgotPasswordEmail}
            element={<ForgotPasswordEmailPage />}
          />
          <Route path={RouteUrl.signUp} element={<SigUpPage />} />

          <Route path={RouteUrl.login} element={<LoginPage />} />
          <Route
            path={RouteUrl.resetPassword}
            element={<ResetPasswordPage />}
          />
        </Route>
        {/*  Inteers */}
        <Route
          path={RouteUrl.interesting}
          element={<ChooseInterestCategory />}
        />
        {/* Private routes */}
        <Route element={<PrivateRoute />}>
          <Route path={RouteUrl.setting} element={<Setting />} />
        </Route>

        {/* Catch-all route for 404 */}
        <Route path="*" element={<NotFoundPage />} />

        {/* Public routes */}
        <Route path={RouteUrl.welcome} element={<WelcomePage />} />

        <Route path={RouteUrl.shopMenu} element={<ShopMenuPage />} />
        <Route path={RouteUrl.termOfPrivacy} element={<TermOfPrivacyPage />} />
        <Route path={RouteUrl.termOfServices} element={<TermOfServices />} />
        <Route path={RouteUrl.profile} element={<Profile />} />
        <Route path={RouteUrl.viewShop} element={<ViewShop />} />
        <Route path={RouteUrl.viewProduct} element={<ViewProduct />} />
        <Route path={RouteUrl.following} element={<Following />} />
        <Route path={RouteUrl.newsFeed} element={<NewsFeed />} />
        <Route path={RouteUrl.marketPlaces} element={<MarketPlaces />} />
        <Route
          path={RouteUrl.profilePostContentsPageView}
          element={<ProfilePostContentsPageView />}
        />
      </Routes>
    </Router>
  );
}

export default App;
