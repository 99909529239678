export const formatSecondToHour = (value: number) => {
  if (value < 60) {
    return `${value}s`; // less than 60 seconds
  } else if (value >= 60 && value < 3600) {
    const minutes = Math.floor(value / 60);
    return `${minutes}m`; // between 1 min and 1 hour
  } else {
    const hours = Math.floor(value / 3600);
    return `${hours}h`; // 1 hour or more
  }
};
export const postedTimeAgo = (date: string): string => {
  try {
    const inputDate = new Date(date);
    const now = new Date();
    const diff = Math.abs(now.getTime() - inputDate.getTime());

    const diffInSeconds = Math.floor(diff / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);
    const diffInYears = now.getFullYear() - inputDate.getFullYear();
    const diffInMonths =
      (now.getFullYear() - inputDate.getFullYear()) * 12 +
      (now.getMonth() - inputDate.getMonth());
    if (diffInYears >= 1) {
      return `${diffInYears} year${diffInYears > 1 ? "s" : ""}`;
    } else if (diffInMonths >= 1) {
      return `${diffInMonths} month${diffInMonths > 1 ? "s" : ""}`;
    } else if (diffInDays >= 1) {
      return `${diffInDays} day${diffInDays > 1 ? "s" : ""}`;
    } else if (diffInHours >= 1) {
      return `${diffInHours} hour${diffInHours > 1 ? "s" : ""}`;
    } else if (diffInMinutes >= 1) {
      return `${diffInMinutes} minute${diffInMinutes > 1 ? "s" : ""}`;
    } else if (diffInSeconds >= 1) {
      return `${diffInSeconds} second${diffInSeconds > 1 ? "s" : ""}`;
    } else {
      return "Now";
    }
  } catch (err) {
    return "Unkow";
  }
};
