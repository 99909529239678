export class RouteUrl {
  static welcome = "/";
  static notFound = "*";
  static login = "/login";
  static forgotPasswordPhone = "/forgot-password-phone";
  static resetPassword = "/reset-password";
  static interesting = "/interesting-category";
  static forgotPasswordEmail = "/forgot-password-mail";
  static signUp = "/sign-up";
  static dashboard = "/dashboard";
  static shopMenu = "/shop/menu";
  static termOfPrivacy = "/term-of-privacy";
  static termOfServices = "/term-of-services";
  static publicProfile = "/profile";
  static viewShop = "/view-shop";
  static viewProduct = "/view-product";
  static profilePostContentsPageView = "/post";
  static following = "/following";
  static newsFeed = "/news-feed";
  static marketPlaces = "/marketplaces";
  static profile = "/profile";
  static setting = "/setting";
}
