import LoadingPage from "assets/icons/loading-page";
import { Snackbar } from "components";
import AlbumPostContent from "components/app/album-post-content/AlbumPostContent";
import PhotoPostContent from "components/app/photo-post-content/PhotoPostContent";
import SearchParamsNotFound from "components/app/search-params-not-found/SearchParamsNotFound";
import StatusPostContent from "components/app/status-post-content/StatusPostContent";

import { ScrollArea } from "components/ui/scroll-area";

import { X } from "lucide-react";
import { AppRecordStatus } from "models/appRecord";
import { StatusMsg } from "models/returnMsg";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart as solidHeart } from "@fortawesome/free-solid-svg-icons";
import { faHeart as outlineHeart } from "@fortawesome/free-regular-svg-icons";

import { AppLog } from "services";
import {
  setLoading,
  setPosts,
  setPost,
  setCurrentIdex,
} from "store/profilePostContentsPageViewSlice";
import { RootState, useAppSelector } from "store/store";
import { RequestApi, RouteApi, RouteUrl } from "utils";
import VideoPostContent from "components/app/video-post-content/VideoPostContent";
import ImageUrlProfile from "components/app/image-url/ImageUrlProfile";
import { comment, favorite, profileVerified, share } from "assets";
import { postedTimeAgo } from "utils/formatTimeSecondToHour";
import LinkifyText from "components/app/linkify/LinkifyText";
import ArrowUpDown from "./ArrowUpDown";
import AudioPlayer from "components/app/post-content-audio-player/AudioPlayer ";
import {
  requestSubmitFollowingUser,
  requestSubmitUnFollowinguUser,
} from "api-function/requestFriendStatusApi";
import BtnFriendStatus from "components/app/btn-friend-status/BtnFriendStatus";
import HelmetHtml from "components/app/helmet-html/HelmetHtml";
export default function ProfilePostContentsPageView() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("post_id");
  const [direction, setDirection] = useState<string>("");
  const dispatch = useDispatch();
  const [searchID, setSearchID] = useState<boolean>(false);
  const { post, posts, postsState, prePostId, currentIndex } = useAppSelector(
    (state) => state.profilePostContentsPageView
  );
  const { token } = useAppSelector((state) => state.auth);
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated
  );

  const handleUp = () => {
    if (currentIndex < posts.length - 1) {
      let tmpIndex = currentIndex + 1;
      dispatch(setCurrentIdex(tmpIndex));
      setDirection("down");

      navigate(
        `${RouteUrl.profilePostContentsPageView}?post_id=${posts[tmpIndex].post_id}`,
        { replace: true }
      );
      if (tmpIndex === posts.length - 1) {
        const postId = posts[posts.length - 1].post_id;

        fetchProfilePostContent({
          isRefresh: false,
          postId: postId,
          direction: direction,
        });
      }
    }
  };
  const handleDown = () => {
    if (currentIndex > 0) {
      let tmpIndex = currentIndex - 1;
      dispatch(setCurrentIdex(tmpIndex));
      setDirection("up");

      navigate(
        `${RouteUrl.profilePostContentsPageView}?post_id=${posts[tmpIndex].post_id}`,
        { replace: true }
      );
    }
  };
  const fetchProfilePostContent = async ({
    isRefresh = false,
    direction = "",
    postId,
  }: { isRefresh?: boolean; direction?: string; postId?: string } = {}) => {
    try {
      if (isRefresh) {
        dispatch(
          setLoading({ ...postsState, loadingStatus: AppRecordStatus.loading })
        );
      } else {
        dispatch(
          setLoading({
            ...postsState,
            loadingStatus: AppRecordStatus.loadingMore,
          })
        );
      }
      let url: string = "";

      if (isAuthenticated) {
        url = RouteApi.getHomeProfilePost;
        url += `token=${token}`;
      } else {
        url = RouteApi.getProfilePostContentsPageView;
      }
      await RequestApi(url, {
        data: { post_id: postId ?? id, direction: direction },
      })
        .then((responses) => {
          if (responses.status === "success") {
            let tmpPosts = [];
            if (responses["post"]) {
              tmpPosts[0] = responses["post"];
            } else {
              tmpPosts = isRefresh
                ? [...responses["posts"]]
                : [...posts, ...responses["posts"]];
            }

            if (isRefresh) {
              const tmpPost = tmpPosts.find((post) => post.post_id === id);

              if (tmpPost) {
                dispatch(setPost(tmpPost));
              }
            }

            dispatch(setPosts(tmpPosts));

            if (responses["posts"] === null && responses["post"] === null) {
              setSearchID(true);
            }
            AppLog({ message: `post Length ${tmpPosts.length}` });
          } else {
            Snackbar({
              message: responses["msg"],
              status: StatusMsg.getType(responses["status"]),
            });
          }
          setLoading(false);
          dispatch(
            setLoading({ ...postsState, loadingStatus: AppRecordStatus.loaded })
          );
        })
        .catch((err) => {
          Snackbar({
            message: err.toString(),
            status: StatusMsg.failed,
          });
          setLoading(false);
          dispatch(
            setLoading({ ...postsState, loadingStatus: AppRecordStatus.loaded })
          );
        });
    } catch (ex: any) {
      AppLog({ message: ex.toString(), status: StatusMsg.error });
      setLoading(false);
      dispatch(
        setLoading({ ...postsState, loadingStatus: AppRecordStatus.loaded })
      );
    }
  };

  useEffect(() => {
    if (prePostId !== id && id) {
      console.log("Fetching profile post content for ID:", id);
      dispatch(setCurrentIdex(0));
      fetchProfilePostContent({ isRefresh: true, postId: id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, prePostId]);
  const findPost = () => {
    try {
      console.log("==========fin post");

      const tmpPost = posts.find((post) => post.post_id === id);

      if (tmpPost) {
        dispatch(setPost(tmpPost));
      }
    } catch (err) {
      AppLog({ message: `catch findPost ${err}` });
    }
  };
  useEffect(() => {
    if (id !== prePostId) {
      console.log("find curr on back");
      findPost();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  const postContents = () => {
    if (post) {
      if (post?.media_type === "photo") {
        return (
          <div
            key={post?.post_attachments[0].id}
            style={{
              aspectRatio: `${
                post?.post_attachments[0]?.attachment_media_width ?? 16
              } / ${post?.post_attachments[0]?.attachment_media_height ?? 9}`,
            }}
          >
            <div className="flex justify-center items-center">
              <ArrowUpDown
                value={currentIndex}
                handleDown={handleDown}
                handleUp={handleUp}
                isDown={currentIndex === posts.length - 1 ? true : false}
              />
              {post?.sound_url && post?.sound_url !== "" ? (
                <div className="absolute max-sm:bottom-3 max-sm:right-3 bottom-5 right-8  space-x-2  text-white select-none z-50">
                  <AudioPlayer
                    src={post?.sound_url}
                    title={post?.sound_title ?? ""}
                  />
                </div>
              ) : (
                <></>
              )}
              <PhotoPostContent post={post?.post_attachments[0]} />
            </div>
          </div>
        );
      } else if (post?.media_type === "album") {
        return (
          <div className="flex justify-center items-center ">
            <ArrowUpDown
              value={currentIndex}
              handleDown={handleDown}
              handleUp={handleUp}
              isDown={currentIndex === posts.length - 1 ? true : false}
            />
            {post?.sound_url && post?.sound_url !== "" ? (
              <div className="absolute max-sm:bottom-3 max-sm:right-3 bottom-5 right-8  space-x-2  text-white select-none z-50">
                <AudioPlayer
                  src={post?.sound_url}
                  title={post?.sound_title ?? ""}
                />
              </div>
            ) : (
              <></>
            )}
            <AlbumPostContent post={post} />
          </div>
        );
      } else if (post?.media_type === "video") {
        return (
          <div className="flex justify-center items-center">
            <ArrowUpDown
              value={currentIndex}
              handleDown={handleDown}
              handleUp={handleUp}
              isDown={currentIndex === posts.length - 1 ? true : false}
            />
            <VideoPostContent postAttchment={post} />
          </div>
        );
      } else if (post?.media_type === "status") {
        return (
          <div className="flex  justify-center items-center ">
            <ArrowUpDown
              value={currentIndex}
              handleDown={handleDown}
              handleUp={handleUp}
              isDown={currentIndex === posts.length - 1 ? true : false}
            />
            <StatusPostContent post={post} />
          </div>
        );
      }
    } else {
      return <></>;
    }
  };
  // ============= request api function
  const onClickFriend = () => {
    let user = post?.re_post_user !== null ? post?.re_post_user : post?.user;
    if (
      user?.friend_status === "Friend" ||
      user?.friend_status === "Following"
    ) {
      requestUnFollowing();
    } else {
      requestFollowing();
    }
  };
  const requestFollowing = async () => {
    const userId =
      post?.re_post_user !== null
        ? post?.re_post_user_id?.toString()
        : post?.user_id?.toString();
    const result = await requestSubmitFollowingUser({ userId: userId });
    if (result.status === "success") {
      updatePosts(result, userId);
    }
  };

  const requestUnFollowing = async () => {
    const userId =
      post?.re_post_user !== null
        ? post?.re_post_user_id?.toString()
        : post?.user_id?.toString();
    const result = await requestSubmitUnFollowinguUser({ userId: userId });
    console.log("----stat");
    if (result.status === "success") {
      updatePosts(result, userId);
    }
  };
  const updatePosts = (result: any, userId: string) => {
    try {
      const tmpPosts = posts.map((post) => {
        const postCopy = { ...post }; // Shallow copy of the post

        if (
          post["re_post_user"] !== null &&
          post["re_post_user"] !== undefined
        ) {
          if (post["re_post_user"]["id"]?.toString() === userId) {
            postCopy.re_post_user = { ...post.re_post_user };
            postCopy.re_post_user.friend_status =
              result?.user?.friend_status || null;
          }
        } else if (post["user"] !== null && post["user"] !== undefined) {
          if (post["user_id"]?.toString() === userId) {
            console.log("user");
            postCopy.user = { ...post.user };
            postCopy.user.friend_status = result?.user?.friend_status || null;
          }
        }

        return postCopy; // Return the modified copy
      });
      const tmpPost = tmpPosts.find((post) => post.post_id === id);
      dispatch(setPost(tmpPost));
      dispatch(setPosts(tmpPosts));
      console.log("succe");
    } catch (err) {
      console.log("errr", err);
    }
  };

  return (
    <>
      {!id || id === "" || searchID ? (
        <SearchParamsNotFound params="id" />
      ) : (
        <>
          {postsState.loadingStatus === AppRecordStatus.loading ? (
            <LoadingPage />
          ) : (
            // <Layout>
            <div className="flex flex-col lg:grid lg:grid-cols-3  overflow-hidden h-[100vh]">
              <HelmetHtml
                title="Post-Content"
                description="This is PostContent"
              />
              <div className="  bg-black lg:col-span-2 md:h-[60vh] lg:h-auto items-center text-center">
                <div
                  className="absolute inset-0 bg-cover bg-center bg-black opacity-50 blur-3xl "
                  style={{
                    backgroundImage: `url("${post?.post_attachments[0]?.attachment_thumbnail_image_file_src}")`,
                  }}
                ></div>

                <div className="relative z-50  select-none">
                  <X
                    className="absolute max-sm:top-3 top-5 max-sm:left-3 left-5  rounded-full max-sm:size-8 size-11 max-sm:p-1 p-2 bg-opacity-10 text-white cursor-pointer bg-black shadow-md"
                    onClick={() => navigate(-1)}
                  />
                </div>

                <div className={`relative flex items-center justify-center `}>
                  {post?.media_type === "status" ? (
                    <>{postContents()}</>
                  ) : post?.media_type === "video" ? (
                    <>{postContents()}</>
                  ) : (
                    <>{postContents()}</>
                  )}
                </div>
              </div>

              <ScrollArea className="lg:col-span-1 p-4 lg:h-[100vh] h-screen bg-white">
                <div className="">
                  <div className="flex flex-col relative space-y-2">
                    <div className="flex flex-row items-start space-x-2 w-full  ">
                      <ImageUrlProfile
                        user={post?.user}
                        style={{ width: 50, height: 50 }}
                        className="rounded-full object-cover border shadow-md shadow-gray-300"
                        isLinkProfile={true}
                      />
                      <div className="flex flex-row justify-between items-center  w-full">
                        <div className="flex flex-col ">
                          <div className="flex items-center space-x-2">
                            <p className=" text-xl font-semibold">
                              {post?.user?.name}
                            </p>
                            {post?.user?.is_verified === "Yes" ? (
                              <img
                                src={profileVerified}
                                alt=""
                                style={{ width: 12, height: 12 }}
                              />
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="flex items-center space-x-2">
                            <p>
                              {post?.is_sponsored === "Yes"
                                ? "Sponsored"
                                : post?.is_available_suggestion === "Yes"
                                ? "Suggestion for you"
                                : `${postedTimeAgo(
                                    post?.provider_created_datetime
                                  )}`}
                            </p>
                          </div>
                        </div>
                        <BtnFriendStatus
                          friendStatus={
                            post?.re_post_user !== null
                              ? post?.re_post_user?.friend_status
                              : post?.user?.friend_status
                          }
                          userType={post?.user?.user_type}
                          onClick={onClickFriend}
                        />
                      </div>
                    </div>
                    <LinkifyText
                      text={post?.post_attachments[0]?.description ?? ""}
                    />
                    <hr />
                    <div className="flex flex-row justify-between w-full">
                      <div className="flex flex-row justify-start w-full space-x-2">
                        <div className="flex w-9 h-9  rounded-full bg-gray-200 text-center items-center justify-center">
                          <FontAwesomeIcon
                            icon={
                              post?.is_reaction_like?.toString() !== "0"
                                ? solidHeart
                                : outlineHeart
                            }
                            size="lg"
                            className={` ${
                              post?.is_reaction_like?.toString() !== "0"
                                ? "heart-icon text-red-600 heart-fill"
                                : "heart-outline outline-black"
                            }`}
                          />
                        </div>
                        <div className="flex  w-9 h-9  rounded-full bg-gray-200 text-center items-center justify-center">
                          <img src={comment} alt="" width={25} height={25} />
                        </div>
                        <div className="flex w-9 h-9  rounded-full pb-2 bg-gray-200 text-center items-center justify-center">
                          <img src={share} alt="" width={25} height={25} />
                        </div>
                        <div className="flex w-9 h-9 p-1 rounded-full bg-gray-200 text-center items-center justify-center">
                          <img src={favorite} alt="" width={25} height={25} />
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row justify-between w-full">
                      <div className="flex items-center space-x-2 w-full">
                        {post?.like_users && post?.like_users.length > 0 ? (
                          <>
                            <div className="flex justify-center -space-x-3 rtl:space-x-reverse ">
                              {post?.like_users.map(
                                (likeUser: any, indexFriUser: number) => {
                                  return (
                                    <ImageUrlProfile
                                      key={indexFriUser}
                                      isLinkProfile={true}
                                      user={likeUser}
                                      className="w-6 h-6 border-2 border-white rounded-full dark:border-gray-800"
                                      alt=""
                                    />
                                  );
                                }
                              )}
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        <p className="font-normal">{`${
                          post?.reaction_like_count === 0
                            ? ""
                            : post?.reaction_like_count
                        } ${
                          post?.reaction_like_count === 0
                            ? ""
                            : post?.reaction_like_count > 1
                            ? "Likes"
                            : "Like"
                        } ${
                          post?.comment_count === 0 ? "" : post?.comment_count
                        } ${
                          post?.comment_count === 0
                            ? ""
                            : post?.comment_count > 1
                            ? "Comments"
                            : "Comment"
                        }  ${
                          post?.reaction_share_count === "0" ||
                          post?.reaction_share_count === 0
                            ? ""
                            : post?.reaction_share_count
                        } ${
                          post?.reaction_share_count === "0" ||
                          post?.reaction_share_count === 0
                            ? ""
                            : post?.reaction_share_count !== "1"
                            ? "Shares"
                            : "Share"
                        } `}</p>
                        {post?.reach_users && post?.reach_users.length > 0 ? (
                          <div className="flex justify-end items-end ">
                            <div className="flex justify-center -space-x-4 rtl:space-x-reverse ">
                              {post?.reach_users.map(
                                (viewUser: any, indexFriUser: number) => {
                                  return (
                                    <ImageUrlProfile
                                      key={indexFriUser}
                                      isLinkProfile={true}
                                      user={viewUser}
                                      className="w-6 h-6 border-2 border-white rounded-full dark:border-gray-800"
                                      alt=""
                                    />
                                  );
                                }
                              )}
                            </div>
                            <p className="font-normal ">
                              {post?.reach_count > 1
                                ? `${post?.reach_count} Views`
                                : `${post?.reach_count} View`}
                            </p>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </ScrollArea>
            </div>
            // </Layout>
          )}
        </>
      )}
    </>
  );
}
