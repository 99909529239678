import { Card } from "components/ui/card";
import React from "react";
import SettingLeftSideContent from "./setting-left-side-content/SettingLeftSideContent";
export default function SettingContentLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <div className="flex">
      <SettingLeftSideContent />
      <div className="flex justify-center w-full p-4 bg-gray-100">
        <Card className="w-[400px] md:w-[600px] lg:w-[900px] p-4">
          {children}
        </Card>
      </div>
    </div>
  );
}
