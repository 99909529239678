import { createSlice } from "@reduxjs/toolkit";

interface SettingSlice {
  recordUserSetting: any;
}

const initialState: SettingSlice = {
  recordUserSetting: null,
};

const userSettingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    setRecordUserSetting(state, action) {
      state.recordUserSetting = action.payload;
    },
  },
});

export const { setRecordUserSetting } = userSettingSlice.actions;

const userSettingReducer = userSettingSlice.reducer;
export default userSettingReducer;
