// src/store/slices/counterSlice.ts

import { AppRecord, AppRecordStatus } from "models/appRecord";
import { createSlice } from "@reduxjs/toolkit";
import { stat } from "fs";
// import { AppRecord, AppRecordStatus } from "../../../models/appRecord";

interface ProfilePostContentsPageViewSlice {
  postsState: AppRecord;
  posts: any[];
  post: any;
  prePostId: string;
  currentIndex: number;
}

const initialState: ProfilePostContentsPageViewSlice = {
  postsState: {
    currentPage: 1,
    currentIndex: 0,
    nextPageUrl: "",
    totalRecord: 0,
    loadingStatus: AppRecordStatus.loading,
  },
  currentIndex: 0,
  prePostId: "",
  posts: [],
  post: {},
};

const profilePostContentsPageViewSlice = createSlice({
  name: "profilePostContentsPageView",
  initialState,
  reducers: {
    setLoading(state, action) {
      state.postsState = action.payload;
    },
    setCurrentIdex(state, action) {
      state.currentIndex = action.payload;
    },
    setPosts(state, action) {
      state.posts = action.payload;
    },
    setPost(state, action) {
      state.post = action.payload;
      state.prePostId = action.payload.post_id;
    },
  },
});

export const { setLoading, setPosts, setPost, setCurrentIdex } =
  profilePostContentsPageViewSlice.actions;

const profilePostContentsPageViewReducer =
  profilePostContentsPageViewSlice.reducer;
export default profilePostContentsPageViewReducer;
