import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Snackbar } from "components";
import SwitchYesNoWithLabel from "components/app/function/SwitchYesNoWithLabel";
import Layout from "layout/layout";
import SettingContentLayout from "layout/setting-content/SettingContentLayout";
import { Handshake, Heart } from "lucide-react";
import { StatusMsg } from "models/returnMsg";
import { useEffect, useState } from "react";
import { AppLog } from "services";
import { useAppSelector } from "store/store";
import { RequestApi, RouteApi } from "utils";
import { faHandshake } from "@fortawesome/free-regular-svg-icons";
import { useDispatch } from "react-redux";
import { setRecordUserSetting } from "store/userSettingSlice";
import SwitchValueWithLabelAndSameAPIKey from "components/app/function/SwitchValueWithLabelAndSameAPIKey";

export default function Setting() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const { token } = useAppSelector((state) => state.auth);
  const { recordUserSetting } = useAppSelector((state) => state.userSetting);

  const fetchGetProfile = async ({
    isRefresh = false,
  }: {
    isRefresh?: boolean;
  }) => {
    try {
      // setLoadingGetProfile(true);
      if (isRefresh) {
        setLoading(true);
      }
      let url: string = "";

      url = RouteApi.getUserSetting;
      url += `token=${token}`;

      await RequestApi(url)
        .then((responses) => {
          if (responses.status === "success") {
            console.log("response", responses);

            dispatch(setRecordUserSetting(responses["user"]));
          } else {
            Snackbar({
              message: responses["msg"],
              status: StatusMsg.getType(responses["status"]),
            });
          }
          setLoading(false);
          // setLoadingGetProfile(false);
        })
        .catch((err) => {
          Snackbar({
            message: err.toString(),
            status: StatusMsg.failed,
          });
        });

      setLoading(false);
      // setLoadingGetProfile(false);
    } catch (ex: any) {
      AppLog({ message: ex.toString(), status: StatusMsg.error });
      setLoading(false);
      // setLoadingGetProfile(false);
    }
  };

  useEffect(() => {
    fetchGetProfile({ isRefresh: true });

    // eslint-disable-next-line
  }, []);

  return (
    <Layout>
      <SettingContentLayout>
        <h1 className="font-semibold">Setting, Secuity, and Privacy</h1>
        <div className="grid grid-cols-2 mt-4 gap-4">
          <div className="">
            <div className="space-y-2">
              <div className="flex items-center space-x-2">
                <FontAwesomeIcon icon={faHandshake} />
                <h1 className="font-semibold">I am looking for partner</h1>
              </div>
              {/* <SwitchValueWithLabelAndSameAPIKey /> */}
              {/* <SwitchYesNoWithLabel
                id="looking_for_partner"
                label="Friends"
                icon={<Heart />}
                value={recordUserSetting?.looking_for_partner}
                onChange={(answer: string) => {
                  const tmpRecordUserSetting = {
                    ...recordUserSetting,
                    allow_change_price: answer,
                  };
                  dispatch(setRecordUserSetting(tmpRecordUserSetting));
                }}
              /> */}
            </div>
          </div>
          <div className="bg-slate-100">
            <h1>I am looking for partner</h1>
          </div>
        </div>
      </SettingContentLayout>
    </Layout>
  );
}
