import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import {
  flipFlopChatLogo,
  logoFacebook,
  logoTelegram,
  logoTiktok,
  logoYoutube,
  noneProfile,
  profileVerified,
} from "assets";
import NewsFeedIcon from "assets/icons/news-feed-icon";
import { Snackbar } from "components";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "components/app/dialog/dialog";
import ImageUrl from "components/app/image-url/ImageUrl";
import ImageUrlProfile from "components/app/image-url/ImageUrlProfile";
import QrCodeGenerater from "components/app/qr-code-generater/QrCodeGenerater";
import SearchParamsNotFound from "components/app/search-params-not-found/SearchParamsNotFound";
import { Button } from "components/ui/button";
import { Card } from "components/ui/card";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from "components/ui/carousel";
import { ScrollArea } from "components/ui/scroll-area";
import Layout from "layout/layout";
import {
  AlignLeft,
  Contact,
  Copy,
  Heart,
  House,
  Info,
  LoaderCircle,
  MapPin,
} from "lucide-react";
import { faShare as solidShare } from "@fortawesome/free-solid-svg-icons";
import { faHeart as solidHeart } from "@fortawesome/free-solid-svg-icons";
import { StatusMsg } from "models/returnMsg";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { AppLog } from "services";
import {
  setFriendUsers,
  setPosts,
  setShares,
  setUser,
  setUserCoverPictures,
  setPostLoading,
  setSharesLoading,
  setLoves,
  setLovesLoading,
  setLikes,
  setLikesLoading,
  clearState,
  setFollowerUsers,
  setFollowerLoading,
  setFollowingUsers,
  setFollowingLoading,
  setFriendLoading,
} from "store/profileSlice";
import { useAppSelector } from "store/store";
import { RequestApi, RouteApi, RouteUrl } from "utils";
import Masonry from "react-masonry-css";
import CarouselWithIndex from "components/app/carousel-with-index/CarouselWithIndex";
import VideoHoverPlay from "components/app/video-hover-play/VideoHoverPlay";
import StatusHoverPlay from "components/app/status-hover-play/StatusHoverPlay";
import ImageHoverPlay from "components/app/image-hover-play/ImageHoverPlay";
import { Separator } from "components/ui/separator";
import { AppRecord, AppRecordStatus } from "models/appRecord";
import InfiniteScroll from "react-infinite-scroll-component";
import SearchBar from "components/app/search-bar/SearchBar";
import { Skeleton } from "components/ui/skeleton";
import BtnFriendStatus from "components/app/btn-friend-status/BtnFriendStatus";
import GoogleMapPreview from "components/app/google-map/GoogleMapPreview";
import {
  requestSubmitFollowingUser,
  requestSubmitUnFollowinguUser,
} from "api-function/requestFriendStatusApi";
import HelmetHtml from "components/app/helmet-html/HelmetHtml";
import { qrFlipFlopAndroid, qrFlipFlopIOS } from "assets";
import { set } from "date-fns";
import AlertWellcome from "pages/auth/alert/AlertWellcome";

export default function Profile() {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dynamicParam = useRef<any>();
  const { token } = useAppSelector((state) => state.auth);
  const [friendUsersForAvatar, setFriendUsersForAvatar] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingGetProfile, setLoadingGetProfile] = useState<boolean>(false);
  const [subLoading, setSubLoading] = useState<boolean>(false);
  const [loadingTap, setLoadingTap] = useState<boolean>(false);
  const [subLoadingTap, setSubLoadingTap] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const userAgent = navigator.userAgent;
  const { isAuthenticated } = useAppSelector((state) => state.auth);
  const [showAlert, setShowAlert] = useState<boolean>(false);

  const searchParamsUrl = new URLSearchParams(location.search);
  const ParamId = searchParamsUrl.get("id");

  const {
    posts,
    user,
    userCoverPictures,
    friendUsers,
    shares,
    postsState,
    sharesState,
    loves,
    lovesState,
    likes,
    likesState,
    followerUsers,
    friendUserState,
    followerUserState,
    followingUsers,
    followingUserState,
  } = useAppSelector((state) => state.profile);

  const qrDetecting = () => {
    // IOS
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return (
        <div className="mt-2">
          <div className="flex justify-center mb-2">
            <ImageUrl width={100} height={100} src={qrFlipFlopIOS} alt="logo" />
          </div>

          <Link to="https://apps.apple.com/us/app/flipflop-social-network/id6465576581">
            <Button className="font-semibold text-sm">
              Yes, I want to download!
            </Button>
          </Link>
          <p className="text-center">
            FlipFlop is a social media app dedicated to short-form videos
            created for and consumed by users. The length of the videos is 15
            seconds to 15 Minutes. The format lends itself to entertainment and
            comedy. However, it is increasingly used for infotainment. So-called
            influencers who gain a steady audience on FlipFlop offer snippets of
            advice and tips along with self-promotion. Beauty, fashion, personal
            finance, and cooking are all popular topics for informational
            videos. Increasingly, the format is used to promote and sell
            products. Like all social media companies, FlipFlop has been the
            target of persistent concerns about the potential use or misuse of
            the private information that it gathers about its users. The
            difference is that the majority of FlipFlop is Cambodia-owned.
          </p>
        </div>
      );
    }
    // ANDROID
    else if (/android/i.test(userAgent)) {
      return (
        <div className="mt-2">
          <div className="flex justify-center mb-2">
            <ImageUrl
              width={100}
              height={100}
              src={qrFlipFlopAndroid}
              alt="logo"
            />
          </div>
          <Link to="https://play.google.com/store/apps/details?id=com.maxx4business.flip_flop&hl=en">
            <Button className="font-semibold text-sm">
              Yes, I want to download!
            </Button>
          </Link>
          <p className="text-center">
            FlipFlop is a social media app dedicated to short-form videos
            created for and consumed by users. The length of the videos is 15
            seconds to 15 Minutes. The format lends itself to entertainment and
            comedy. However, it is increasingly used for infotainment. So-called
            influencers who gain a steady audience on FlipFlop offer snippets of
            advice and tips along with self-promotion. Beauty, fashion, personal
            finance, and cooking are all popular topics for informational
            videos. Increasingly, the format is used to promote and sell
            products. Like all social media companies, FlipFlop has been the
            target of persistent concerns about the potential use or misuse of
            the private information that it gathers about its users. The
            difference is that the majority of FlipFlop is Cambodia-owned.
          </p>
        </div>
      );
    }
    // Other Device
    else {
      return (
        <div className="mt-2 w-full">
          <div className="flex justify-evenly w-full mb-2">
            <div>
              <p className="font-semibold text-sm">App Store</p>
              <ImageUrl
                width={200}
                height={200}
                src={qrFlipFlopIOS}
                alt="ios-qr"
              />
            </div>
            <div>
              <p className="font-semibold text-sm">Google Play</p>
              <ImageUrl
                width={200}
                height={200}
                src={qrFlipFlopAndroid}
                alt="android-qr"
              />
            </div>
          </div>
          <p className="text-center">
            FlipFlop is a social media app dedicated to short-form videos
            created for and consumed by users. The length of the videos is 15
            seconds to 15 Minutes. The format lends itself to entertainment and
            comedy. However, it is increasingly used for infotainment. So-called
            influencers who gain a steady audience on FlipFlop offer snippets of
            advice and tips along with self-promotion. Beauty, fashion, personal
            finance, and cooking are all popular topics for informational
            videos. Increasingly, the format is used to promote and sell
            products. Like all social media companies, FlipFlop has been the
            target of persistent concerns about the potential use or misuse of
            the private information that it gathers about its users. The
            difference is that the majority of FlipFlop is Cambodia-owned.
          </p>
        </div>
      );
    }
  };
  const breakpointColumnsObj = {
    default: 5,
    1536: 5,
    1280: 4,
    1024: 4,
    768: 3,
    425: 2,
  };

  // Loop through all search params to find any dynamic ones (e.g., 'feed','posts')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  for (const [key, value] of searchParams.entries()) {
    if (key !== "id") {
      // Exclude the 'id' key
      dynamicParam.current = key;
    }
  }

  const fetchMoreProfile = async ({ next }: { next: string }) => {
    if (dynamicParam.current === "posts") {
      dispatch(
        setPostLoading({
          ...postsState,
          loadingStatus: AppRecordStatus.loadingMore,
        })
      );
    } else if (dynamicParam.current === "share") {
      dispatch(
        setSharesLoading({
          ...sharesState,
          loadingStatus: AppRecordStatus.loadingMore,
        })
      );
    } else if (dynamicParam.current === "love") {
      dispatch(
        setLovesLoading({
          ...lovesState,
          loadingStatus: AppRecordStatus.loadingMore,
        })
      );
    } else if (dynamicParam.current === "like") {
      dispatch(
        setLikesLoading({
          ...likesState,
          loadingStatus: AppRecordStatus.loadingMore,
        })
      );
    }
    await RequestApi(next, { data: { flag: dynamicParam.current } }).then(
      (responses) => {
        if (responses.status === "success") {
          const stateRecord: AppRecord = {
            currentPage: 1,
            currentIndex: 0,
            nextPageUrl: responses["posts"]["next_page_url"] ?? "",
            totalRecord: 0,
            loadingStatus:
              responses["posts"]["next_page_url"] != null
                ? AppRecordStatus.loaded
                : AppRecordStatus.noLoadMore,
          };

          if (dynamicParam.current === "posts") {
            const tmpPosts = [...posts["data"], ...responses["posts"]["data"]];
            const newPost = { ...posts, data: tmpPosts };
            dispatch(setPosts(newPost));
            dispatch(setPostLoading(stateRecord));
          } else if (dynamicParam.current === "share") {
            const tmpPosts = [...shares["data"], ...responses["posts"]["data"]];
            const newPost = { ...shares, data: tmpPosts };
            dispatch(setShares(newPost));
            dispatch(setSharesLoading(stateRecord));
          } else if (dynamicParam.current === "love") {
            const tmpPosts = [...loves["data"], ...responses["posts"]["data"]];
            const newPost = { ...loves, data: tmpPosts };
            dispatch(setLoves(newPost));
            dispatch(setLovesLoading(stateRecord));
          } else if (dynamicParam.current === "like") {
            const tmpPosts = [...likes["data"], ...responses["posts"]["data"]];
            const newPost = { ...likes, data: tmpPosts };
            dispatch(setLikes(newPost));
            dispatch(setLikesLoading(stateRecord));
          }
        }
      }
    );
  };

  // API Content
  const fetchProfile = async ({
    id,
    isRefresh = false,
  }: {
    id: string;
    isRefresh?: boolean;
  }) => {
    try {
      setLoadingTap(true);
      if (isRefresh) {
        setLoading(true);
      }
      let url: string = "";
      if (isAuthenticated) {
        if (
          ["feed", "posts", "share", "love", "like"].includes(
            dynamicParam.current
          )
        ) {
          url = RouteApi.getProfilePost;
          url += `token=${token}`;
        }

        const data = JSON.stringify({
          user_id: id,
          flag: dynamicParam.current === "posts" ? "" : dynamicParam.current,
        });

        if (
          ![
            "community_friends",
            "community_follower",
            "community_following",
            "info",
          ].includes(dynamicParam.current)
        ) {
          await RequestApi(url, { data })
            .then((responses) => {
              if (responses.status === "success") {
                const stateRecord: AppRecord = {
                  currentPage: 1,
                  currentIndex: 0,
                  nextPageUrl: responses["posts"]["next_page_url"] ?? "",
                  totalRecord: 0,
                  loadingStatus:
                    responses["posts"]["next_page_url"] != null
                      ? AppRecordStatus.loaded
                      : AppRecordStatus.noLoadMore,
                };
                if (dynamicParam.current === "posts") {
                  dispatch(setPosts(responses["posts"]));
                  dispatch(setPostLoading(stateRecord));
                } else if (dynamicParam.current === "share") {
                  dispatch(setShares(responses["posts"]));
                  dispatch(setSharesLoading(stateRecord));
                } else if (dynamicParam.current === "love") {
                  dispatch(setLoves(responses["posts"]));
                  dispatch(setLovesLoading(stateRecord));
                } else if (dynamicParam.current === "like") {
                  dispatch(setLikes(responses["posts"]));
                  dispatch(setLikesLoading(stateRecord));
                }
              } else {
                Snackbar({
                  message: responses["msg"],
                  status: StatusMsg.getType(responses["status"]),
                });
              }
              setLoading(false);
              setLoadingTap(false);
            })
            .catch((err) => {
              Snackbar({
                message: err.toString(),
                status: StatusMsg.failed,
              });
            });
        }
      }
      setLoadingTap(false);
      setLoading(false);
    } catch (ex: any) {
      AppLog({ message: ex.toString(), status: StatusMsg.error });
      setLoading(false);
      setLoadingTap(false);
    }
  };

  const fetchGetProfile = async ({
    id,
    isRefresh = false,
  }: {
    id: string;
    isRefresh?: boolean;
  }) => {
    try {
      setLoadingGetProfile(true);
      if (isRefresh) {
        setLoading(true);
      }
      let url: string = "";
      if (isAuthenticated) {
        url = RouteApi.getProfile;
        url += `token=${token}`;

        const data = JSON.stringify({
          user_id: id,
        });

        await RequestApi(url, { data })
          .then((responses) => {
            if (responses.status === "success") {
              dispatch(setUser(responses["user"]));
              setFriendUsersForAvatar(responses["friend_users"]);
              dispatch(setUserCoverPictures(responses["user_cover_pictures"]));
            } else {
              Snackbar({
                message: responses["msg"],
                status: StatusMsg.getType(responses["status"]),
              });
            }
            setLoading(false);
            setLoadingGetProfile(false);
          })
          .catch((err) => {
            Snackbar({
              message: err.toString(),
              status: StatusMsg.failed,
            });
          });
      } else {
        url = RouteApi.getPublicProfile;
        url += `id=${ParamId}`;
        await RequestApi(url)
          .then((responses) => {
            if (responses.status === "success") {
              console.log("responses", responses);
              dispatch(setUser(responses["user"]));
              dispatch(setUserCoverPictures(responses["user_cover_pictures"]));
              setFriendUsersForAvatar(responses["friend_users"]);
            } else {
              Snackbar({
                message: responses["msg"],
                status: StatusMsg.getType(responses["status"]),
              });
            }
          })
          .catch((err) => {
            Snackbar({
              message: err.toString(),
              status: StatusMsg.failed,
            });
          });
      }

      setLoading(false);
      setLoadingGetProfile(false);
    } catch (ex: any) {
      AppLog({ message: ex.toString(), status: StatusMsg.error });
      setLoading(false);
      setLoadingGetProfile(false);
    }
  };

  // API SubContent
  const fetchSubTapContent = async ({
    isRefresh = false,
  }: {
    isRefresh?: boolean;
  }) => {
    try {
      setSubLoadingTap(true);
      if (isRefresh) {
        setSubLoading(true);
      }

      let url: string = "";
      if (isAuthenticated) {
        if (dynamicParam.current === "community_friends") {
          url = RouteApi.getFriendUser;
          url += `token=${token}`;
        } else if (dynamicParam.current === "community_follower") {
          url = RouteApi.getFollowerUser;
          url += `token=${token}`;
        } else if (dynamicParam.current === "community_following") {
          url = RouteApi.getFollowingUser;
          url += `token=${token}`;
        }

        const data = JSON.stringify({
          user_id: id,
          search_text: searchText,
        });

        await RequestApi(url, { data })
          .then((responses) => {
            if (responses.status === "success") {
              if (dynamicParam.current === "community_friends") {
                const stateRecord: AppRecord = {
                  currentPage: 1,
                  currentIndex: 0,
                  nextPageUrl: responses["friend_users"]["next_page_url"] ?? "",
                  totalRecord: 0,
                  loadingStatus:
                    responses["friend_users"]["next_page_url"] != null
                      ? AppRecordStatus.loaded
                      : AppRecordStatus.noLoadMore,
                };
                dispatch(setFriendUsers(responses["friend_users"]));
                dispatch(setFriendLoading(stateRecord));
              } else if (dynamicParam.current === "community_follower") {
                const stateRecord: AppRecord = {
                  currentPage: 1,
                  currentIndex: 0,
                  nextPageUrl:
                    responses["follower_users"]["next_page_url"] ?? "",
                  totalRecord: 0,
                  loadingStatus:
                    responses["follower_users"]["next_page_url"] != null
                      ? AppRecordStatus.loaded
                      : AppRecordStatus.noLoadMore,
                };

                dispatch(setFollowerUsers(responses["follower_users"]));
                dispatch(setFollowerLoading(stateRecord));
              } else if (dynamicParam.current === "community_following") {
                const stateRecord: AppRecord = {
                  currentPage: 1,
                  currentIndex: 0,
                  nextPageUrl:
                    responses["following_users"]["next_page_url"] ?? "",
                  totalRecord: 0,
                  loadingStatus:
                    responses["following_users"]["next_page_url"] != null
                      ? AppRecordStatus.loaded
                      : AppRecordStatus.noLoadMore,
                };
                dispatch(setFollowingUsers(responses["following_users"]));
                dispatch(setFollowingLoading(stateRecord));
              }
            } else {
              Snackbar({
                message: responses["msg"],
                status: StatusMsg.getType(responses["status"]),
              });
            }
            setSubLoading(false);
            setSubLoadingTap(false);
          })
          .catch((err) => {
            Snackbar({
              message: err.toString(),
              status: StatusMsg.failed,
            });
          });
        setSubLoadingTap(false);
        setSubLoading(false);
      }
    } catch (ex: any) {
      AppLog({ message: ex.toString(), status: StatusMsg.error });
      setSubLoading(false);
      setSubLoadingTap(false);
    }
  };

  const fetchMoreSubTapContent = async ({ next }: { next: string }) => {
    if (dynamicParam.current === "community_friends") {
      dispatch(
        setFollowerLoading({
          ...friendUserState,
          loadingStatus: AppRecordStatus.loadingMore,
        })
      );
    } else if (dynamicParam.current === "community_follower") {
      dispatch(
        setFollowerLoading({
          ...followerUserState,
          loadingStatus: AppRecordStatus.loadingMore,
        })
      );
    } else if (dynamicParam.current === "community_following") {
      dispatch(
        setFollowingLoading({
          ...followingUserState,
          loadingStatus: AppRecordStatus.loadingMore,
        })
      );
    }

    await RequestApi(next).then((responses) => {
      if (responses.status === "success") {
        if (dynamicParam.current === "community_friends") {
          const stateRecord: AppRecord = {
            currentPage: 1,
            currentIndex: 0,
            nextPageUrl: responses["friend_users"]["next_page_url"] ?? "",
            totalRecord: 0,
            loadingStatus:
              responses["friend_users"]["next_page_url"] != null
                ? AppRecordStatus.loaded
                : AppRecordStatus.noLoadMore,
          };

          const tmpPosts = [
            ...friendUsers["data"],
            ...responses["friend_users"]["data"],
          ];
          const newPost = { ...friendUsers, data: tmpPosts };
          dispatch(setFriendUsers(newPost));
          dispatch(setFriendLoading(stateRecord));
        } else if (dynamicParam.current === "community_follower") {
          const stateRecord: AppRecord = {
            currentPage: 1,
            currentIndex: 0,
            nextPageUrl: responses["follower_users"]["next_page_url"] ?? "",
            totalRecord: 0,
            loadingStatus:
              responses["follower_users"]["next_page_url"] != null
                ? AppRecordStatus.loaded
                : AppRecordStatus.noLoadMore,
          };

          const tmpPosts = [
            ...followerUsers["data"],
            ...responses["follower_users"]["data"],
          ];

          const newPost = { ...followerUsers, data: tmpPosts };

          dispatch(setFollowerUsers(newPost));
          dispatch(setFollowerLoading(stateRecord));
        } else if (dynamicParam.current === "community_following") {
          const stateRecord: AppRecord = {
            currentPage: 1,
            currentIndex: 0,
            nextPageUrl: responses["following_users"]["next_page_url"] ?? "",
            totalRecord: 0,
            loadingStatus:
              responses["following_users"]["next_page_url"] != null
                ? AppRecordStatus.loaded
                : AppRecordStatus.noLoadMore,
          };

          const tmpPosts = [
            ...followingUsers["data"],
            ...responses["following_users"]["data"],
          ];
          const newPost = { ...followingUsers, data: tmpPosts };
          dispatch(setFollowingUsers(newPost));
          dispatch(setFollowingLoading(stateRecord));
        }
      }
    });
  };

  useEffect(() => {
    if (id && id !== user?.id?.toString()) {
      fetchProfile({ id, isRefresh: true });
      fetchGetProfile({ id, isRefresh: true });
      dispatch(clearState());
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (
      [
        "community_friends",
        "community_follower",
        "community_following",
      ].includes(dynamicParam.current)
    ) {
      if (searchText === "") {
        fetchSubTapContent({ isRefresh: true });
      } else {
        fetchSubTapContent({ isRefresh: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  // Check if the URL already contains '&feed'
  useEffect(() => {
    if (!location.search.includes("posts")) {
      if (!dynamicParam.current) {
        // Manually append '&posts' to the current URL
        const newUrl = `${location.pathname}${location.search}&posts`;
        navigate(newUrl, { replace: true }); // Update the URL without reloading the page
      }
    }
  }, [location, navigate]);

  const navLinkProfileOfContent = [
    {
      url: "feed",
      name: "Feed",
      icon: <NewsFeedIcon height="30" width="30" />,
    },
    {
      url: "posts",
      name: "Posts",
      icon: <AlignLeft size={30} />,
    },
    {
      url: "share",
      name: "Share",
      icon: <FontAwesomeIcon icon={solidShare} size="2x" />,
    },
    {
      url: "love",
      name: "Love",
      icon: (
        <FontAwesomeIcon icon={solidHeart} size="2x" className="text-red-600" />
      ),
    },
    {
      url: "like",
      name: "Like",
      icon: (
        <FontAwesomeIcon
          icon={solidHeart}
          size="2x"
          className="text-blue-400"
        />
      ),
    },
    {
      url: "community_friends",
      name: "Friends",
      icon: <Contact size={30} />,
    },
    {
      url: "info",
      name: "Info",
      icon: <Info size={30} />,
    },
  ];

  const onGoToPost = (postId: string) => {
    navigate(`${RouteUrl.profilePostContentsPageView}?post_id=${postId}`);
  };

  const postContents = (item: any) => {
    if (item) {
      if (item?.media_type === "photo") {
        if (item?.post_attachments.length > 0) {
          return (
            <div className="relative">
              <div onClick={() => onGoToPost(item.post_id)}>
                <ImageHoverPlay post={item} />
              </div>
              {/* <ContentInfo post={item} /> */}
            </div>
          );
        } else {
          return (
            <img
              src="https://via.placeholder.com/150x300"
              className="w-full h-auto rounded-lg"
              alt=""
            />
          );
        }
      } else if (item?.media_type === "album") {
        if (item?.post_attachments.length > 0) {
          return (
            <div className="relative">
              <div onClick={() => onGoToPost(item.post_id)}>
                <CarouselWithIndex
                  item={item}
                  imgClassName="w-full h-auto object-cover rounded-lg cursor-pointer"
                  indexClassName=""
                />
              </div>
              {/* <ContentInfo post={item} /> */}
            </div>
          );
        } else {
          return (
            <img
              src="https://via.placeholder.com/150x300"
              className="w-full h-auto rounded-lg"
              alt=""
            />
          );
        }
      } else if (item?.media_type === "video") {
        if (item?.post_attachments.length > 0) {
          return (
            <div className="relative">
              <div onClick={() => onGoToPost(item.post_id)}>
                <VideoHoverPlay post={item} />
              </div>
              {/* <ContentInfo post={item} /> */}
            </div>
          );
        } else {
          return (
            <img
              src="https://via.placeholder.com/150x300"
              className="w-full h-auto rounded-lg"
              alt=""
            />
          );
        }
      } else if (item?.media_type === "status") {
        if (item?.post_attachments.length > 0) {
          return (
            <div className="relative" onClick={() => onGoToPost(item.post_id)}>
              <StatusHoverPlay post={item} />
              {/* <ContentInfo post={item} /> */}
            </div>
          );
        } else {
          return (
            <img
              src="https://via.placeholder.com/150x300"
              className="w-full h-auto rounded-lg"
              alt=""
            />
          );
        }
      } else {
        return (
          <img
            src="https://via.placeholder.com/150x300"
            className="w-full h-auto rounded-lg"
            alt=""
          />
        );
      }
    } else return <></>;
  };

  const subNavlink = [
    {
      url: "community_friends",
      name: user?.user_type === "Personal" ? "Friends" : "Networking",
    },
    { url: "community_follower", name: "Follower" },
    { url: "community_following", name: "Following" },
  ];

  const subNavLinkContent = () => {
    if (dynamicParam.current === "community_friends") {
      if (!friendUsers) return;
      return (
        <div className="p-1">
          {!subLoading && (
            <InfiniteScroll
              className={`relative`}
              dataLength={friendUsers["data"]?.length}
              next={() =>
                fetchMoreSubTapContent({ next: friendUserState.nextPageUrl })
              }
              hasMore={friendUserState.nextPageUrl === "" ? false : true}
              loader={
                <div className="absolute bottom-2 left-0 w-full flex justify-center">
                  <LoaderCircle
                    size={30}
                    className="animate-spin text-yellow-400"
                  />
                </div>
              }
              endMessage={
                <p className="absolute bottom-0 left-0 w-full text-center">
                  <b>No additional users loaded</b>
                </p>
              }
            >
              {subLoadingTap && (
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-3">
                  {[...Array(20)].map((_, i) => {
                    return (
                      <Skeleton key={i} className="h-[400px] rounded-md" />
                    );
                  })}
                </div>
              )}
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-3 mb-8">
                {friendUsers?.data.map((user: any, index: number) => {
                  return (
                    <Card key={index} className="rounded-md">
                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          navigate(`/profile?id=${user?.id}&feed`);
                        }}
                      >
                        <ImageUrlProfile
                          className="w-full object-cover h-[200px] rounded-t-md"
                          user={user}
                        />
                        {/* Name */}
                        <div className="p-2">
                          <div className="flex">
                            <h1 className="font-semibold text-lg line-clamp-1">
                              {user?.name}
                            </h1>
                            {user?.is_verified === "Yes" ? (
                              <>
                                <div className="flex items-center justify-center pt-0.5 ml-1">
                                  <img
                                    src={profileVerified}
                                    style={{ width: 24, height: 24 }}
                                    alt=""
                                  />
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                          <p
                            className={`line-clamp-1 ${
                              user?.bio === "" ? "invisible" : ""
                            }`}
                          >
                            {user?.bio !== "" ? user?.bio : "NoBio"}
                          </p>
                        </div>
                      </div>

                      {/* Follower Post Like */}
                      <div className="grid grid-cols-3 text-center">
                        <div className="">
                          <h1 className="font-semibold">
                            {user?.no_of_follower}
                          </h1>
                          <p className="text-xs">Followers</p>
                        </div>
                        <div className="">
                          <h1 className="font-semibold">{user?.no_of_post}</h1>
                          <p className="text-xs">Posts</p>
                        </div>
                        <div className="">
                          <h1 className="font-semibold">{user?.no_of_like}</h1>
                          <p className="text-xs">Likes</p>
                        </div>
                      </div>

                      <div className="grid grid-cols-1 p-2">
                        <BtnFriendStatus
                          friendStatus={user?.friend_status}
                          onClick={() => onClickFriend({ user, isArray: true })}
                          userType={user?.user_type}
                        />
                      </div>
                    </Card>
                  );
                })}
              </div>
            </InfiniteScroll>
          )}
        </div>
      );
    } else if (dynamicParam.current === "community_follower") {
      if (!followerUsers) return;
      return (
        <div className="p-1">
          {!subLoading && (
            <InfiniteScroll
              className={`relative`}
              dataLength={followerUsers["data"]?.length}
              next={() =>
                fetchMoreSubTapContent({ next: followerUserState.nextPageUrl })
              }
              hasMore={followerUserState.nextPageUrl === "" ? false : true}
              loader={
                <div className="absolute bottom-2 left-0 w-full flex justify-center">
                  <LoaderCircle
                    size={30}
                    className="animate-spin text-yellow-400"
                  />
                </div>
              }
              endMessage={
                <p className="absolute bottom-0 left-0 w-full text-center">
                  <b>No additional users loaded</b>
                </p>
              }
            >
              {subLoadingTap && (
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-3">
                  {[...Array(20)].map((_, i) => {
                    return <Skeleton key={i} className="h-[400px]" />;
                  })}
                </div>
              )}
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-3 mb-8">
                {followerUsers?.data.map((user: any, index: number) => {
                  return (
                    <Card key={index} className="rounded-md">
                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          navigate(`/profile?id=${user?.id}&feed`);
                        }}
                      >
                        <ImageUrlProfile
                          className="w-full object-cover h-[200px] rounded-t-md"
                          user={user}
                        />
                        {/* Name */}
                        <div className="p-2">
                          <div className="flex">
                            <h1 className="font-semibold text-lg line-clamp-1">
                              {user?.name}
                            </h1>
                            {user?.is_verified === "Yes" ? (
                              <>
                                <div className="flex items-center justify-center pt-0.5 ml-1">
                                  <img
                                    src={profileVerified}
                                    style={{ width: 24, height: 24 }}
                                    alt=""
                                  />
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                          <p
                            className={`line-clamp-1 ${
                              user?.bio === "" ? "invisible" : ""
                            }`}
                          >
                            {user?.bio !== "" ? user?.bio : "NoBio"}
                          </p>
                        </div>
                      </div>

                      {/* Follower Post Like */}
                      <div className="grid grid-cols-3 text-center">
                        <div className="">
                          <h1 className="font-semibold">
                            {user?.no_of_follower}
                          </h1>
                          <p className="text-xs">Followers</p>
                        </div>
                        <div className="">
                          <h1 className="font-semibold">{user?.no_of_post}</h1>
                          <p className="text-xs">Posts</p>
                        </div>
                        <div className="">
                          <h1 className="font-semibold">{user?.no_of_like}</h1>
                          <p className="text-xs">Likes</p>
                        </div>
                      </div>

                      <div className="grid grid-cols-1 p-2">
                        <BtnFriendStatus
                          friendStatus={user?.friend_status}
                          onClick={() =>
                            onClickFriend({
                              user,
                              isArray: true,
                            })
                          }
                          userType={user?.user_type}
                        />
                      </div>
                    </Card>
                  );
                })}
              </div>
            </InfiniteScroll>
          )}
        </div>
      );
    } else if (dynamicParam.current === "community_following") {
      if (!followingUsers) return;
      return (
        <div className="p-1">
          {!subLoading && (
            <InfiniteScroll
              className={`relative`}
              dataLength={followingUsers["data"].length}
              next={() =>
                fetchMoreSubTapContent({
                  next: followingUserState?.nextPageUrl,
                })
              }
              hasMore={followingUserState.nextPageUrl === "" ? false : true}
              loader={
                <div className="absolute bottom-2 left-0 w-full flex justify-center">
                  <LoaderCircle
                    size={30}
                    className="animate-spin text-yellow-400"
                  />
                </div>
              }
              endMessage={
                <p className="absolute bottom-0 left-0 w-full text-center">
                  <b>No additional users loaded</b>
                </p>
              }
            >
              {subLoadingTap && (
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-3">
                  {[...Array(20)].map((_, i) => {
                    return <Skeleton key={i} className="h-[400px]" />;
                  })}
                </div>
              )}
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-3 mb-8">
                {followingUsers?.data.map((user: any, index: number) => {
                  return (
                    <Card key={index} className="rounded-md">
                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          navigate(`/profile?id=${user?.id}&feed`);
                        }}
                      >
                        <ImageUrlProfile
                          className="w-full object-cover h-[200px] rounded-t-md"
                          user={user}
                        />
                        {/* Name */}
                        <div className="p-2">
                          <div className="flex">
                            <h1 className="font-semibold text-lg line-clamp-1">
                              {user?.name}
                            </h1>
                            {user?.is_verified === "Yes" ? (
                              <>
                                <div className="flex items-center justify-center pt-0.5 ml-1">
                                  <img
                                    src={profileVerified}
                                    style={{ width: 24, height: 24 }}
                                    alt=""
                                  />
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                          <p
                            className={`line-clamp-1 ${
                              user?.bio === "" ? "invisible" : ""
                            }`}
                          >
                            {user?.bio !== "" ? user?.bio : "NoBio"}
                          </p>
                        </div>
                      </div>

                      {/* Follower Post Like */}
                      <div className="grid grid-cols-3 text-center">
                        <div className="">
                          <h1 className="font-semibold">
                            {user?.no_of_follower}
                          </h1>
                          <p className="text-xs">Followers</p>
                        </div>
                        <div className="">
                          <h1 className="font-semibold">{user?.no_of_post}</h1>
                          <p className="text-xs">Posts</p>
                        </div>
                        <div className="">
                          <h1 className="font-semibold">{user?.no_of_like}</h1>
                          <p className="text-xs">Likes</p>
                        </div>
                      </div>

                      <div className="grid grid-cols-1 p-2">
                        <BtnFriendStatus
                          friendStatus={user?.friend_status}
                          onClick={() =>
                            onClickFriend({
                              user,
                              isArray: true,
                            })
                          }
                          userType={user?.user_type}
                        />
                      </div>
                    </Card>
                  );
                })}
              </div>
            </InfiniteScroll>
          )}
        </div>
      );
    }
  };

  const navLinkContent = () => {
    if (dynamicParam.current === "feed") {
      return (
        <>
          {/* Info Profile */}
          {/* <div className="col-span-5 w-full">
            <Card className="p-4 space-y-2 mb-3">
              <h1 className="font-bold text-lg font-san">Intro</h1>
              <p className="text-center">This is Bio</p>
              <Button variant="secondary" className="w-full font-semibold">
                Edit Bio
              </Button>
            </Card>

            <Card className="p-4 space-y-2 mb-3">
              <h1 className="font-bold text-lg font-san">Photos</h1>
              <div className="grid grid-cols-3">
                <ImageUrl src="" />
                <ImageUrl src="" />
                <ImageUrl src="" />
                <ImageUrl src="" />
                <ImageUrl src="" />
              </div>
            </Card>

            <Card className="p-4 space-y-2 mb-3">
              <div>
                <h1 className="font-bold text-lg font-san">Friends</h1>
                <p className="text-sm font-semibold text-gray-500">
                  577 friends
                </p>
              </div>
              <div className="grid grid-cols-3">
                <ImageUrl src="" />
                <ImageUrl src="" />
                <ImageUrl src="" />
                <ImageUrl src="" />
                <ImageUrl src="" />
              </div>
            </Card>
          </div> */}

          {/* Content Profile */}
          {/* <div className="col-span-7">
            <Card className="p-4 space-y-2 mb-3">
              <div className="flex items-center">
                <ImageUrlProfile
                  isLinkProfile={false}
                  className="rounded-full object-cover border shadow-md shadow-gray-600"
                  user=""
                  style={{ width: 36, height: 36 }}
                  alt="user"
                />
                <div className="flex flex-col items-start justify-center ml-2">
                  <div className="flex">
                    <p className="text-xs font-bold line-clamp-1">Namefdsf</p>
                    {post?.user?.is_verified ? (
                      <>
                        <div className="flex items-center justify-center pt-0.5 ml-1">
                          <img
                            src={profileVerified}
                            alt=""
                            style={{ width: 12, height: 12 }}
                          />
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    <img
                      src={profileVerified}
                      alt=""
                      style={{ width: 12, height: 12 }}
                    />
                  </div>
                  <p className="text-xs text-gray-500 line-clamp-1 font-semibold">
                    September 20 at 11:41PM
                  </p>
                </div>
              </div>
              <p className="">This is description</p>
              <div>Content</div>
            </Card>
          </div> */}
        </>
      );
    } else if (dynamicParam.current === "posts") {
      if (!posts) return;
      return (
        <>
          <InfiniteScroll
            className={`relative min-h-[1000px]`}
            dataLength={posts["data"]?.length}
            next={() => fetchMoreProfile({ next: postsState.nextPageUrl })}
            hasMore={postsState.nextPageUrl === "" ? false : true}
            loader={
              <div className="absolute bottom-2 left-0 w-full flex justify-center">
                <LoaderCircle
                  size={30}
                  className="animate-spin text-yellow-400"
                />
              </div>
            }
            endMessage={
              <p className="absolute bottom-0 left-0 w-full text-center">
                <b>Yay! you have seen all items.</b>
              </p>
            }
          >
            <Masonry
              breakpointCols={breakpointColumnsObj}
              className="my-masonry-grid w-full mb-8"
              columnClassName="my-masonry-grid_column"
            >
              {posts &&
                posts?.data.map((post: any, index: number) => (
                  <div
                    id={`${post?.id}`}
                    key={index}
                    className="relative group break-inside-avoid bg-whiterounded-lg rounded-xl bg-black mb-3"
                  >
                    {postContents(post)}
                  </div>
                ))}
            </Masonry>
          </InfiniteScroll>
        </>
      );
    } else if (dynamicParam.current === "share") {
      if (!shares) return;
      return (
        <>
          <InfiniteScroll
            className={`relative  min-h-[1000px]`}
            dataLength={shares["data"]?.length}
            next={() => fetchMoreProfile({ next: sharesState.nextPageUrl })}
            hasMore={sharesState.nextPageUrl === "" ? false : true}
            loader={
              <div className="absolute bottom-2 left-0 w-full flex justify-center">
                <LoaderCircle
                  size={30}
                  className="animate-spin text-yellow-400"
                />
              </div>
            }
            endMessage={
              <p className="absolute bottom-0 left-0 w-full text-center">
                <b>Yay! you have seen all items.</b>
              </p>
            }
          >
            <Masonry
              breakpointCols={breakpointColumnsObj}
              className="my-masonry-grid w-full mb-8"
              columnClassName="my-masonry-grid_column"
            >
              {shares &&
                shares?.data.map((post: any, index: number) => (
                  <div
                    id={`${post?.id}`}
                    key={index}
                    className="relative group mb-3 break-inside-avoid bg-whiterounded-lg rounded-xl bg-black"
                  >
                    {postContents(post)}
                  </div>
                ))}
            </Masonry>
          </InfiniteScroll>
        </>
      );
    } else if (dynamicParam.current === "love") {
      if (!loves) return;
      return (
        <>
          <InfiniteScroll
            className={`relative  min-h-[1000px]`}
            dataLength={loves["data"]?.length}
            next={() => fetchMoreProfile({ next: lovesState.nextPageUrl })}
            hasMore={lovesState.nextPageUrl === "" ? false : true}
            loader={
              <div className="absolute bottom-2 left-0 w-full flex justify-center">
                <LoaderCircle
                  size={30}
                  className="animate-spin text-yellow-400"
                />
              </div>
            }
            endMessage={
              <p className="absolute bottom-0 left-0 w-full text-center">
                <b>Yay! you have seen all items.</b>
              </p>
            }
          >
            <Masonry
              breakpointCols={breakpointColumnsObj}
              className="my-masonry-grid w-full mb-8"
              columnClassName="my-masonry-grid_column"
            >
              {loves &&
                loves?.data.map((post: any, index: number) => (
                  <div
                    id={`${post?.id}`}
                    key={index}
                    className="relative group mb-3 break-inside-avoid bg-whiterounded-lg rounded-xl bg-black"
                  >
                    {postContents(post)}
                  </div>
                ))}
            </Masonry>
          </InfiniteScroll>
        </>
      );
    } else if (dynamicParam.current === "like") {
      if (!likes) return;
      return (
        <>
          <InfiniteScroll
            className={`relative  min-h-[1000px]`}
            dataLength={likes["data"]?.length}
            next={() => fetchMoreProfile({ next: likesState.nextPageUrl })}
            hasMore={likesState.nextPageUrl === "" ? false : true}
            loader={
              <div className="absolute bottom-2 left-0 w-full flex justify-center">
                <LoaderCircle
                  size={30}
                  className="animate-spin text-yellow-400"
                />
              </div>
            }
            endMessage={
              <p className="absolute bottom-0 left-0 w-full text-center">
                <b>Yay! you have seen all items.</b>
              </p>
            }
          >
            <Masonry
              breakpointCols={breakpointColumnsObj}
              className="my-masonry-grid w-full mb-8"
              columnClassName="my-masonry-grid_column"
            >
              {likes &&
                likes?.data.map((post: any, index: number) => (
                  <div
                    id={`${post?.id}`}
                    key={index}
                    className="relative group mb-3 break-inside-avoid bg-whiterounded-lg rounded-xl bg-black"
                  >
                    {postContents(post)}
                  </div>
                ))}
            </Masonry>
          </InfiniteScroll>
        </>
      );
    } else if (
      dynamicParam.current === "community_friends" ||
      dynamicParam.current === "community_follower" ||
      dynamicParam.current === "community_following"
    ) {
      return (
        <>
          <Card className="p-4 space-y-2 mb-3">
            <div className="flex justify-between">
              <h1 className="font-bold text-lg font-san">Communities</h1>
              <SearchBar
                value={searchText}
                onValueChange={(value) => setSearchText(value)}
              />
            </div>

            {/* SubNavLink */}
            <div className="flex items-center">
              {subNavlink.map((link: any, indexLink: number) => {
                return (
                  <button
                    key={indexLink}
                    onClick={() => {
                      // setSubNavLinkContentValue(link.url);
                      dynamicParam.current = link.url;
                      onTapSubNavContent(link.url);
                    }}
                    className={`px-4 py-2 hover:bg-slate-200 rounded-lg font-semibold
                      ${
                        dynamicParam.current === link.url
                          ? "border-amber-400 text-amber-400 border-b-4"
                          : ""
                      }`}
                  >
                    {link.name}
                  </button>
                );
              })}
            </div>

            {/* Card SubNavLink */}
            <div className="min-h-[1000px]">{subNavLinkContent()}</div>
          </Card>
        </>
      );
    } else if (dynamicParam.current === "info") {
      return (
        <div className="flex justify-center">
          <div className="w-[750px] min-h-[1000px] bg-white rounded-lg p-4">
            {user?.professional ? (
              <div className="mb-3">
                <p className="text-lg font-bold">Professional</p>
                <div className="flex items-center space-x-3 p-1 mb-3">
                  <Info />
                  <p className="text-sm font-semibold">{user?.professional}</p>
                </div>
                <Separator />
              </div>
            ) : (
              <></>
            )}

            {user?.birth_address ? (
              <div className="mb-3">
                <p className="text-lg font-bold">Birth Address</p>
                <div className="flex items-center space-x-3 p-1 mb-3">
                  <House />
                  <p className="text-sm font-semibold">
                    From {user.birth_address}
                  </p>
                </div>
                <Separator />
              </div>
            ) : (
              <></>
            )}

            {user?.current_address ? (
              <div className="mb-3">
                <p className="text-lg font-bold">Current Address</p>
                <div className="flex items-center space-x-3 p-1 mb-3">
                  <MapPin />
                  <p className="text-sm font-semibold">
                    From {user?.current_address}
                  </p>
                </div>
                <Separator />
              </div>
            ) : (
              <></>
            )}

            {user?.marital_status ? (
              <div className="mb-3">
                <p className="text-lg font-bold">Marital Status</p>
                <div className="flex items-center space-x-3 p-1 mb-3">
                  <Heart />
                  <p className="text-sm font-semibold">
                    {user?.marital_status}
                  </p>
                </div>
                <Separator />
              </div>
            ) : (
              <></>
            )}

            {user?.facebook_link ? (
              <div className="mb-3">
                <p className="text-lg font-bold">Facebook</p>
                <div className="flex items-center space-x-3 p-1 mb-3">
                  <i className="fa-brands fa-facebook fa-lg" />
                  <Link
                    to={user?.facebook_link}
                    className="text-sm font-semibold"
                  >
                    {user?.facebook_link}
                  </Link>
                </div>
                <Separator />
              </div>
            ) : (
              <></>
            )}

            {user?.telegram_link ? (
              <div className="mb-3">
                <p className="text-lg font-bold">Telegram</p>
                <div className="flex items-center space-x-3 p-1 mb-3">
                  <i className="fa-brands fa-telegram fa-lg" />
                  <Link
                    to={user?.telegram_link}
                    className="text-sm font-semibold"
                  >
                    {user?.telegram_link}
                  </Link>
                </div>
                <Separator />
              </div>
            ) : (
              <></>
            )}

            {user?.youtube_link ? (
              <div className="mb-3">
                <p className="text-lg font-bold">Youtube</p>
                <div className="flex items-center space-x-3 p-1 mb-3">
                  <i className="fa-brands fa-youtube fa-lg" />
                  <Link
                    to={user?.youtube_link}
                    className="text-sm font-semibold"
                  >
                    {user?.youtube_link}
                  </Link>
                </div>
                <Separator />
              </div>
            ) : (
              <></>
            )}

            {user?.tiktok_link ? (
              <div className="mb-3">
                <p className="text-lg font-bold">TikTok</p>
                <div className="flex items-center space-x-3 p-1 mb-3">
                  <i className="fa-brands fa-tiktok fa-lg" />
                  <Link
                    to={user?.tiktok_link}
                    className="text-sm font-semibold"
                  >
                    {user?.tiktok_link}
                  </Link>
                </div>
                <Separator />
              </div>
            ) : (
              <></>
            )}

            <div className="mb-3">
              <p className="text-lg font-bold">FlipFlop</p>
              <div className="flex items-center space-x-3 p-1 mb-3">
                <Copy />
                <p
                  onClick={() => navigate(`/profile?id=${id}`)}
                  className="text-sm font-semibold cursor-pointer"
                >
                  {process.env.REACT_APP_URL}/profile?id={id}
                </p>
              </div>
              <Separator />
            </div>

            <div className="mb-3">
              <p className="text-lg font-bold">Joined FlipFlop</p>
              <div className="flex items-center space-x-3 p-1 mb-3">
                <Info />
                <p className="text-sm font-semibold">
                  Joined FlipFlop on{" "}
                  {moment(user?.created_at).format("MMMM DD, YYYY")}
                </p>
              </div>
            </div>

            {user?.user_type === "Page" ? (
              <>
                {user?.current_latitude && user?.current_longitude ? (
                  <div className="mb-3">
                    <p className="text-lg font-bold">Google Map</p>
                    <div className="h-[400px] w-full mt-2">
                      <GoogleMapPreview
                        latitude={user?.location_latitude}
                        longitude={user?.location_longitude}
                      />
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      );
    }
  };

  const onTapNavContent = (id: string, link: string) => {
    dynamicParam.current = link;
    if (isAuthenticated) {
      if (link === "feed") {
        fetchProfile({ id, isRefresh: false });
      } else if (link === "posts") {
        if (!posts) {
          fetchProfile({ id, isRefresh: false });
        }
      } else if (link === "share") {
        if (!shares) {
          fetchProfile({ id, isRefresh: false });
        }
      } else if (link === "like") {
        if (!likes) {
          fetchProfile({ id, isRefresh: false });
        }
      } else if (link === "love") {
        if (!loves) {
          fetchProfile({ id, isRefresh: false });
        }
      } else if (link === "community_friends") {
        fetchSubTapContent({ isRefresh: false });
      }
    }

    navigate(`${RouteUrl.profile}?id=${id}&${link}`, {
      replace: true,
    });
  };

  const onTapSubNavContent = (link: string) => {
    dynamicParam.current = link;

    if (dynamicParam.current === "community_friends") {
      if (!friendUsers) {
        fetchSubTapContent({ isRefresh: false });
      }
    } else if (dynamicParam.current === "community_follower") {
      if (!followerUsers) {
        fetchSubTapContent({ isRefresh: false });
      }
    } else if (dynamicParam.current === "community_following") {
      if (!followingUsers) {
        fetchSubTapContent({ isRefresh: false });
      }
    }
    navigate(`${RouteUrl.profile}?id=${id}&${link}`, {
      replace: true,
    });
  };

  const requestFollowing = async ({
    user,
    isArray,
  }: {
    user: any;
    isArray: boolean;
  }) => {
    const userId = user?.id;
    const result = await requestSubmitFollowingUser({ userId: userId });
    if (result.status === "success") {
      // if isArray = false It's btn on user Profile
      if (isArray === false) {
        let tmpUser = { ...user };

        tmpUser = result["user"];
        dispatch(setUser(tmpUser));
      } else {
        if (dynamicParam.current === "community_friends") {
          const tmpUserData = [...friendUsers["data"]];
          const findUser = tmpUserData.find(
            (element) => element.id === result["user"]["id"]
          );
          if (findUser) {
            const updatedUserData = tmpUserData.map(
              (element) =>
                element.id === findUser.id
                  ? {
                      ...element,
                      friend_status: result["user"]["friend_status"],
                    } // Update the friend_status field
                  : element // Keep other users unchanged
            );
            dispatch(setFriendUsers({ ...friendUsers, data: updatedUserData }));
          }
        } else if (dynamicParam.current === "community_follower") {
          const tmpUserData = [...followerUsers["data"]];
          const findUser = tmpUserData.find(
            (element) => element.id === result["user"]["id"]
          );

          if (findUser) {
            const updatedUserData = tmpUserData.map(
              (element) =>
                element.id === findUser.id
                  ? {
                      ...element,
                      friend_status: result["user"]["friend_status"],
                    } // Update the friend_status field
                  : element // Keep other users unchanged
            );

            dispatch(
              setFollowerUsers({ ...friendUsers, data: updatedUserData })
            );
          }
        } else if (dynamicParam.current === "community_following") {
          const tmpUserData = [...followingUsers["data"]];
          const findUser = tmpUserData.find(
            (element) => element.id === result["user"]["id"]
          );

          if (findUser) {
            const updatedUserData = tmpUserData.map(
              (element) =>
                element.id === findUser.id
                  ? {
                      ...element,
                      friend_status: result["user"]["friend_status"],
                    } // Update the friend_status field
                  : element // Keep other users unchanged
            );

            dispatch(
              setFollowingUsers({ ...friendUsers, data: updatedUserData })
            );
          }
        }
      }
    }
  };

  const requestUnFollowing = async ({
    user,
    isArray,
  }: {
    user: any;
    isArray: boolean;
  }) => {
    const userId = user?.id;
    const result = await requestSubmitUnFollowinguUser({ userId: userId });
    if (result.status === "success") {
      // if isArray = false It's btn on user Profile
      if (isArray === false) {
        let tmpUser = { ...user };
        tmpUser = result["user"];
        dispatch(setUser(tmpUser));
      } else {
        if (dynamicParam.current === "community_friends") {
          const tmpUserData = [...friendUsers["data"]];
          const findUser = tmpUserData.find(
            (element) => element.id === result["user"]["id"]
          );

          if (findUser) {
            const updatedUserData = tmpUserData.map(
              (element) =>
                element.id === findUser.id
                  ? {
                      ...element,
                      friend_status: result["user"]["friend_status"],
                    } // Update the friend_status field
                  : element // Keep other users unchanged
            );

            dispatch(setFriendUsers({ ...friendUsers, data: updatedUserData }));
          }
        } else if (dynamicParam.current === "community_follower") {
          const tmpUserData = [...followerUsers["data"]];
          const findUser = tmpUserData.find(
            (element) => element.id === result["user"]["id"]
          );

          if (findUser) {
            const updatedUserData = tmpUserData.map(
              (element) =>
                element.id === findUser.id
                  ? {
                      ...element,
                      friend_status: result["user"]["friend_status"],
                    } // Update the friend_status field
                  : element // Keep other users unchanged
            );

            dispatch(
              setFollowerUsers({ ...friendUsers, data: updatedUserData })
            );
          }
        } else if (dynamicParam.current === "community_following") {
          const tmpUserData = [...followingUsers["data"]];
          const findUser = tmpUserData.find(
            (element) => element.id === result["user"]["id"]
          );

          if (findUser) {
            const updatedUserData = tmpUserData.map(
              (element) =>
                element.id === findUser.id
                  ? {
                      ...element,
                      friend_status: result["user"]["friend_status"],
                    } // Update the friend_status field
                  : element // Keep other users unchanged
            );

            dispatch(
              setFollowingUsers({ ...friendUsers, data: updatedUserData })
            );
          }
        }
      }
    }
  };

  const onClickFriend = ({
    user,
    isArray,
  }: {
    user: any;
    isArray: boolean;
  }) => {
    if (
      user?.friend_status === "Friend" ||
      user?.friend_status === "Following"
    ) {
      requestUnFollowing({ user, isArray });
    } else {
      requestFollowing({ user, isArray });
    }
  };

  return (
    <>
      <HelmetHtml title="Profile" description="this is profile page" />
      <Layout>
        {!id || id === "" ? (
          <>
            <SearchParamsNotFound params="profile id" />
          </>
        ) : (
          <>
            {!loading && (
              <>
                {loadingGetProfile ? (
                  <div className="border-b-4">
                    <div className="flex flex-col items-center w-full">
                      <div className="relative mb-24">
                        <Skeleton className="rounded-b-3xl w-full h-[200px] sm:h-[300px] md:h-[400px] aspect-[16/9]" />
                        <Skeleton className="absolute -bottom-16 left-[39%] rounded-full w-[140px] h-[140px] border-4" />
                      </div>
                      <div className="space-y-4 mb-4 mt-0.5">
                        <Skeleton className="h-6 w-[500px]" />
                        <Skeleton className="h-6 w-[500px]" />
                        <Skeleton className="h-6 w-[500px]" />
                        <Skeleton className="h-6 w-[500px]" />
                        <div className="flex justify-center items-center space-x-3 ">
                          <Skeleton className="h-8 w-[50px]" />
                          <Skeleton className="h-8 w-[50px]" />
                          <Skeleton className="h-8 w-[50px]" />
                          <Skeleton className="h-8 w-[50px]" />
                          <Skeleton className="h-8 w-[50px]" />
                          <Skeleton className="h-8 w-[50px]" />
                          <Skeleton className="h-8 w-[50px]" />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col justify-center w-full">
                    <div className="border-b-4 border-gray-200">
                      {/* CoverUserProfile */}
                      <div className="">
                        <div className="relative flex justify-center rounded-3xl w-full h-[200px] sm:h-[300px] md:h-[400px]">
                          <Carousel className="h-full border-none">
                            <CarouselContent className="w-full h-[200px] sm:h-[300px] md:h-[400px] border-none rounded-b-3xl aspect-[16/9]">
                              {userCoverPictures.length > 0 ? (
                                <>
                                  {userCoverPictures.map(
                                    (coverPicture: any, coverIndex: number) => {
                                      return (
                                        <CarouselItem
                                          key={coverIndex}
                                          className="rounded-3xl"
                                        >
                                          <img
                                            src={coverPicture?.picture}
                                            className="w-full h-[200px] sm:h-[300px] md:h-[400px] object-cover rounded-b-3xl aspect-[9/16]"
                                            alt="cover"
                                          />
                                        </CarouselItem>
                                      );
                                    }
                                  )}
                                </>
                              ) : (
                                <CarouselItem className="rounded-3xl">
                                  <img
                                    src={noneProfile}
                                    className="w-full h-[200px] sm:h-[300px] md:h-[400px] object-cover rounded-b-3xl aspect-[9/16]"
                                    alt="cover"
                                  />
                                </CarouselItem>
                              )}
                            </CarouselContent>
                          </Carousel>

                          {/* <button className="absolute bottom-4 right-6 border p-2 rounded-full bg-slate-50">
                  <Camera />
                </button> */}
                        </div>

                        {/* UserProfile */}
                        <div className="relative flex justify-center">
                          <div className="absolute -top-16 flex flex-col text-center">
                            <div className="relative">
                              <ImageUrlProfile
                                isLinkProfile={false}
                                user={user}
                                className="rounded-full w-[140px] h-[140px] border-4"
                              />

                              {/* <button className="absolute bottom-2 -right-2 p-1 rounded-full z-20 bg-slate-50">
                <Camera />
              </button> */}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Profile Infomation */}
                      <div className="px-4 space-y-3 mt-24">
                        <div>
                          {/* Name & Bio */}
                          <div className="text-center space-y-2 mb-3">
                            <div className=" flex justify-center">
                              <div className="flex items-center">
                                <p className="text-2xl font-bold line-clamp-1">
                                  {user?.name}
                                </p>
                              </div>
                              {user?.is_verified === "Yes" ? (
                                <>
                                  <div className="flex items-center justify-center pt-0.5 ml-1">
                                    <img
                                      src={profileVerified}
                                      style={{ width: 24, height: 24 }}
                                      alt=""
                                    />
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </div>

                            <h1 className="font-semibold line-clamp-2 text-muted-foreground">
                              {user?.bio}
                            </h1>

                            {/* Socail Contact */}
                            <div className="flex justify-center">
                              <div className="flex justify-center items-center space-x-2">
                                {user?.facebook_link ? (
                                  <Link to={`${user?.facebook_link}`}>
                                    <img
                                      className="w-[30px] h-[30px] border rounded-full cursor-pointer"
                                      src={logoFacebook}
                                      alt="facebook-logo"
                                    />
                                  </Link>
                                ) : (
                                  <></>
                                )}

                                {user?.telegram_link ? (
                                  <Link to={`${user?.telegram_link}`}>
                                    <img
                                      className="w-[30px] h-[30px] border rounded-full cursor-pointer"
                                      src={logoTelegram}
                                      alt="telegram-logo"
                                    />
                                  </Link>
                                ) : (
                                  <></>
                                )}

                                {user?.tiktok_link ? (
                                  <Link to={`${user?.tiktok_link}`}>
                                    <img
                                      className="w-[30px] h-[30px] border rounded-full cursor-pointer"
                                      src={logoTiktok}
                                      alt="tiktok-logo"
                                    />
                                  </Link>
                                ) : (
                                  <></>
                                )}

                                {user?.youtube_link ? (
                                  <Link to={`${user?.youtube_link}`}>
                                    <img
                                      className="w-[30px] h-[30px] border rounded-full cursor-pointer"
                                      src={logoYoutube}
                                      alt="youtube-logo"
                                    />
                                  </Link>
                                ) : (
                                  <></>
                                )}

                                {user?.google_map_link ? (
                                  <Link
                                    className="w-[30px] h-[30px] border rounded-full flex justify-center items-center"
                                    to={`${user?.google_map_link}`}
                                  >
                                    <i className="fa-solid fa-location-dot text-[#E3665C] fa-xl" />
                                  </Link>
                                ) : (
                                  <></>
                                )}

                                <div className="p-1 rounded-full bg-slate-100">
                                  <QrCodeGenerater
                                    linkUrl={`${process.env.REACT_APP_URL}/profile?id=${id}`}
                                  />
                                </div>

                                {user?.friend_status !== "Owner" ? (
                                  <div className="flex justify-center items-center w-[30px] h-[30px] border rounded-full cursor-pointer bg-slate-100">
                                    <img
                                      className="w-5 h-5"
                                      src={flipFlopChatLogo}
                                      alt="youtube-logo"
                                    />
                                  </div>
                                ) : (
                                  <></>
                                )}
                                <div className="ms-3">
                                  {user?.friend_status === "Owner" ? (
                                    <></>
                                  ) : (
                                    <BtnFriendStatus
                                      className="h-[30px]"
                                      friendStatus={user?.friend_status}
                                      onClick={() => {
                                        if (isAuthenticated) {
                                          onClickFriend({
                                            user,
                                            isArray: false,
                                          });
                                        } else {
                                          setShowAlert(true);
                                        }
                                      }}
                                      userType={user?.user_type}
                                    />
                                  )}
                                </div>
                                {/* Friend User Avatar */}
                                <div className="flex justify-center -space-x-4 rtl:space-x-reverse ml-4">
                                  {friendUsersForAvatar?.data.map(
                                    (friUser: any, indexFriUser: number) => {
                                      return (
                                        <ImageUrlProfile
                                          key={indexFriUser}
                                          isLinkProfile={true}
                                          user={friUser}
                                          className="w-8 h-8 border-2 border-white rounded-full dark:border-gray-800"
                                          alt=""
                                        />
                                      );
                                    }
                                  )}

                                  {friendUsersForAvatar?.last_page > 1 ? (
                                    <p className="flex items-center justify-center w-8 h-8 text-xs font-medium text-white bg-gray-700 border-2 border-white rounded-full hover:bg-gray-600 dark:border-gray-800">
                                      +{friendUsersForAvatar?.last_page * 3}
                                    </p>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* Following & Followers & Posts & Likes */}
                        <div className="flex justify-center space-x-2 text-center font-bold text-md">
                          <div className="flex">
                            <p className="mr-1">{user?.no_of_follower}</p>
                            <p>Followers</p>
                          </div>
                          <p> • </p>
                          <Dialog>
                            <DialogTrigger className="flex">
                              <p className="mr-1">{user?.no_of_following}</p>
                              <p>Following</p>
                            </DialogTrigger>
                            <DialogContent>
                              <h1 className="text-center font-semibold">
                                Following
                              </h1>
                              <ScrollArea className="h-96">
                                {[...Array(20)].map((_, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className="flex justify-between mb-4"
                                    >
                                      <div>
                                        <div className="flex items-center gap-1">
                                          <ImageUrlProfile
                                            isLinkProfile={true}
                                            className="rounded-full object-cover border"
                                            user=""
                                            // user={post?.user}
                                            style={{ width: 40, height: 40 }}
                                            alt="user"
                                          />
                                          <div className="flex flex-col items-start justify-center">
                                            <div className="flex">
                                              <p className="text-xs font-semibold line-clamp-1">
                                                {/* {post?.user?.name} */}This
                                                is my Name
                                              </p>
                                              {/* {post?.user?.is_verified === "Yes" ? (
                                  <>
                                    <div className="flex items-center justify-center pt-0.5 ml-1">
                                      <img
                                        src={profileVerified}
                                        style={{ width: 12, height: 12 }}
                                        alt=""
                                      />
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )} */}
                                              <div className="flex items-center justify-center pt-0.5 ml-1">
                                                <img
                                                  src={profileVerified}
                                                  style={{
                                                    width: 12,
                                                    height: 12,
                                                  }}
                                                  alt=""
                                                />
                                              </div>
                                              <span className="text-xs ms-2">
                                                2 hours ago
                                              </span>
                                            </div>
                                            <p className="text-xs text-gray-600 line-clamp-1">
                                              This is my bio
                                              {/* {post?.user?.bio} */}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div>
                                        <Button variant="secondary">
                                          Friend
                                        </Button>
                                      </div>
                                    </div>
                                  );
                                })}
                              </ScrollArea>
                            </DialogContent>
                          </Dialog>
                          <p> • </p>
                          <Dialog>
                            <DialogTrigger className="flex">
                              <p className="mr-1">{user?.no_of_post}</p>
                              <p>Posts</p>
                            </DialogTrigger>
                            <DialogContent isShowBtnClose={false}>
                              <DialogHeader>
                                <DialogTitle className="flex justify-center">
                                  <ImageUrlProfile
                                    className="rounded-full w-[200px] border shadow-md"
                                    user={""}
                                    isLinkProfile={true}
                                  />
                                </DialogTitle>
                                <h5 className="text-center font-bold">
                                  Total Posts
                                </h5>
                                <DialogDescription className="text-center text-black font-semibold">
                                  {user?.name} posted {user?.no_of_post}{" "}
                                  contents
                                </DialogDescription>
                                <DialogClose>
                                  <button className="w-full bg-blue-500 text-white py-2 rounded-lg mt-2">
                                    Ok
                                  </button>
                                </DialogClose>
                              </DialogHeader>
                            </DialogContent>
                          </Dialog>
                          <p> • </p>
                          <Dialog>
                            <DialogTrigger className="flex">
                              <p className="mr-1">{user?.no_of_like}</p>
                              <p>Likes</p>
                            </DialogTrigger>
                            <DialogContent isShowBtnClose={false}>
                              <DialogHeader>
                                <DialogTitle className="flex justify-center">
                                  <ImageUrlProfile
                                    className="rounded-full w-[200px] border shadow-md"
                                    user={""}
                                    isLinkProfile={true}
                                  />
                                </DialogTitle>
                                <h5 className="text-center font-bold">
                                  Total Likes
                                </h5>
                                <DialogDescription className="text-center text-black font-semibold">
                                  {user?.name} recieved a total of{" "}
                                  {user?.no_of_like} Likes across all posts
                                </DialogDescription>
                                <DialogClose>
                                  <button className="w-full bg-blue-500 text-white py-2 rounded-lg mt-2">
                                    Ok
                                  </button>
                                </DialogClose>
                              </DialogHeader>
                            </DialogContent>
                          </Dialog>
                        </div>

                        {/* NavLink Profile icons */}
                        <div className="text-center font-bold text-sm md:text-md">
                          <nav className="sticky top-[800px]">
                            <ul className="flex justify-center">
                              {navLinkProfileOfContent.map(
                                (link: any, indexLinkProfile: number) => {
                                  return (
                                    <div
                                      onClick={() =>
                                        onTapNavContent(id, link.url)
                                      }
                                      className={`hover:bg-slate-200 rounded-sm px-4 py-3 ${
                                        dynamicParam.current === link.url ||
                                        (link.url === "community_friends" &&
                                          [
                                            "community_follower",
                                            "community_following",
                                            "community_friends",
                                          ].includes(dynamicParam.current))
                                          ? "border-amber-400 text-amber-400 border-b-4"
                                          : ""
                                      } `}
                                      key={indexLinkProfile}
                                    >
                                      <li>{link.icon}</li>
                                    </div>
                                  );
                                }
                              )}
                            </ul>
                          </nav>
                        </div>
                      </div>
                    </div>
                    {/* NavLink Content */}
                    <div className="bg-gray-100 flex justify-center p-4">
                      <div className="w-[1200px]">
                        {dynamicParam.current === "community_friends" ||
                        dynamicParam.current === "community_following" ||
                        dynamicParam.current === "community_follower" ||
                        dynamicParam.current === "info" ? (
                          <></>
                        ) : (
                          <>
                            {loadingTap && (
                              <div className="grid grid-cols-2 xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-3">
                                {[...Array(20)].map((_, i) => {
                                  return (
                                    <Skeleton
                                      key={i}
                                      className="h-[300px] rounded-xl bg-white"
                                    />
                                  );
                                })}
                              </div>
                            )}
                          </>
                        )}
                        {isAuthenticated ? (
                          <>{navLinkContent()}</>
                        ) : (
                          <div className="flex justify-center">
                            <div className="mt-2 w-[750px] text-center bg-white p-4 rounded-xl">
                              {user?.user_type === "Page" ? (
                                <>
                                  <p className="">
                                    សូមទាញយកកម្មវិធីបណ្តាញសង្គម FlipFlop
                                  </p>
                                  <p>
                                    ដើម្បីមើលមាតិការថ្មីៗពីគេហទំព័ររបស់យើងខ្ញុំ!
                                  </p>
                                  <p className="mt-1">
                                    Download FlipFlop Social Media and follow us
                                  </p>
                                  <p className="mt-1">
                                    to view our page's content!
                                  </p>
                                </>
                              ) : (
                                <>
                                  <p className="">
                                    សូមទាញយកកមម្មវិធីបណ្តាញសង្គម FlipFlop
                                  </p>
                                  <p>ដើម្បីទទួលមើលមាតិការថ្មីៗរបស់ខ្ញុំ!</p>
                                  <p className="mt-1">
                                    Download FlipFlop Social Media and follow us
                                  </p>
                                  <p className="mt-1">to view my content!</p>
                                </>
                              )}

                              <p className="mt-1 text-sm text-muted-foreground">
                                Scan QR code below to download
                              </p>

                              <div className="">{qrDetecting()}</div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                <AlertWellcome
                  showAlert={showAlert}
                  setShowAlert={setShowAlert}
                />
              </>
            )}
          </>
        )}
      </Layout>
    </>
  );
}
