"use client";

import { Button } from "components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
} from "components/app/dialog/dialog";
import { QrCode } from "lucide-react";
import { QRCode } from "react-qrcode-logo";
import { cn } from "lib/utils";
import { logoFlipFlop } from "assets";
import ImageUrlProfile from "../image-url/ImageUrlProfile";

export default function QrCodeGenerater({
  className,
  linkUrl,
}: {
  className?: string;
  linkUrl: string;
}) {
  return (
    <Dialog>
      <DialogTrigger className={cn(`cursor-pointer`, className)} asChild>
        <QrCode />
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        {/* <div className="absolute top-0 ">
          <ImageUrlProfile
            className="w-[100px]"
            isLinkProfile={false}
            user=""
          />
        </div> */}
        <DialogHeader>
          <DialogTitle>Scan Qr Here</DialogTitle>
          <div className="flex justify-center">
            <QRCode
              size={200}
              ecLevel="Q"
              qrStyle="fluid"
              fgColor="#2078CD"
              logoImage={logoFlipFlop}
              value={linkUrl}
            />
          </div>
        </DialogHeader>
        <DialogFooter className="">
          <DialogClose className="flex justify-center md:justify-end">
            <p className="w-[80px] bg-slate-200 rounded-lg py-1.5 border font-medium text-sm">
              Close
            </p>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
