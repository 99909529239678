import { useSelector } from "react-redux";
import { RootState, useAppSelector } from "../../store/store";
import { RequestApi, RouteApi } from "utils";
import Layout from "layout/layout";
import { useEffect } from "react";
import { Snackbar } from "components";
import { StatusMsg } from "models/returnMsg";
import { AppLog } from "services";
import Explore from "./explore/Explore";

const IndexPage = () => {
  const { token } = useAppSelector((state) => state.auth);

  const initHomeAccount = async () => {
    try {
      let url: string = "";

      url = RouteApi.getHomeAccount;
      url += `token=${token}`;

      await RequestApi(url)
        .then((responses) => {
          if (responses.status === "success") {
            localStorage.setItem(
              "item_groups",
              JSON.stringify(responses["item_groups"])
            );
          } else {
            Snackbar({
              message: responses["msg"],
              status: StatusMsg.getType(responses["status"]),
            });
          }
        })
        .catch((err) => {
          Snackbar({
            message: err.toString(),
            status: StatusMsg.failed,
          });
        });
    } catch (ex: any) {
      AppLog({ message: ex.toString(), status: StatusMsg.error });
    }
  };

  useEffect(() => {
    initHomeAccount();
  }, []);

  return (
    <Layout>
      <Explore />
    </Layout>
  );
};

export default IndexPage;
