const baseURL = process.env.REACT_APP_API_URL; // Access API URL from .env

export class RouteApi {
  // User
  static login = `${baseURL}/login?`;
  static signUp = `${baseURL}/signup?`;
  static submitUpdateOneSignal = `${baseURL}/submit-update-one-signal?`;
  static activation = `${baseURL}/activation?`;
  static resetPassword = `${baseURL}/reset-password?`;
  static activationResetPassword = `${baseURL}/reset-password-activation?`;
  static submitResetPassword = `${baseURL}/submit-reset-password?`;
  static loginByFacebook = `${baseURL}/login-by-facebook?`;
  static loginByGmail = `${baseURL}/login-by-gmail?`;
  static submitFollowingUser = `${baseURL}/submit-following-user?`;
  static submitUnFollowingUser = `${baseURL}/submit-unfollowing-user?`;
  static getPageUser = `${baseURL}/get-page-user?`;
  static getShopUser = `${baseURL}/get-user-shop?`;
  static getUserSetting = `${baseURL}/get-user-setting?`;

  // user category
  static getCategory = `${baseURL}/get-category`;

  // Store
  static getHomeMarketplaceQrMenu = `${baseURL}/get-home-marketplace-qr-menu?`;
  static getHomeMarketPlaceQrMenuMore = `${baseURL}/get-home-marketplace-qr-menu-more?`;
  static getPublicProfile = `${baseURL}/get-public-profile?`;
  static getProfile = `${baseURL}/get-profile?`;
  static getProfilePost = `${baseURL}/get-profile-post?`;
  static getViewShop = `${baseURL}/get-public-shop-profile?`;
  static getPublicProduct = `${baseURL}/get-public-shop-product?`;
  static getProfilePostContentsPageView = `${baseURL}/get-public-profile-post?`;
  static getHomeProfilePost = `${baseURL}/get-home-profile-post?`;

  static getPostWeb = `${baseURL}/get-post-web?`;
  static getHomeAccount = `${baseURL}/get-home-account?`;
  static getPostWebGuest = `${baseURL}/get-post-web-guest?`;

  // Communities
  static getFriendUser = `${baseURL}/get-friend-user?`;
  static getFollowerUser = `${baseURL}/get-follower-user?`;
  static getFollowingUser = `${baseURL}/get-following-user?`;
}
