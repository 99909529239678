// src/components/LinkifyText.tsx

import React, { useState } from "react";
import Linkify from "react-linkify";

interface LinkifyTextProps {
  text: string;
}

const LinkifyText: React.FC<LinkifyTextProps> = ({ text }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const componentDecorator = (href: string, text: string, key: number) => {
    const isEmail = href.startsWith("mailto:");
    const isPhoneNumber =
      href.startsWith("tel:") || (href.startsWith("0") && href.length > 8);

    return (
      <a
        key={key}
        href={href}
        target={isPhoneNumber ? undefined : "_blank"}
        rel="noopener noreferrer"
        className={`text-blue-500 `}
      >
        {text}
      </a>
    );
  };

  // Determine the number of lines to show when collapsed
  const lineLimit = 5;

  return (
    <div className="my-4">
      <Linkify componentDecorator={componentDecorator}>
        <span
          className={`block overflow-hidden transition-all duration-300 ${
            isExpanded ? "max-h-full" : `max-h-[${lineLimit * 1.5}rem]` // Adjust this to your line height
          }`}
          style={{
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: isExpanded ? "unset" : lineLimit,
          }}
        >
          {text}
        </span>
      </Linkify>

      {text.split("\n").length > lineLimit && (
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          className="mt-2 text-blue-500 hover:underline focus:outline-none"
        >
          {isExpanded ? "Show Less" : "Show More"}
        </button>
      )}
    </div>
  );
};

export default LinkifyText;
